import { createSlice } from '@reduxjs/toolkit';
import { EDrawerAnchor } from 'enums/reducer';
import { IDrawer } from 'interfaces/reducer';

const initialState = {
  drawers: [] as IDrawer[],
}

const slice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    fullDrawer: (state, actions) => {
      state.drawers.push({
        open: false,
        width: '100%',
        anchor: EDrawerAnchor.left,
        bg: '#171A1F',
        content: actions.payload,
      });
    },
    leftDrawer: (state, actions) => {
      state.drawers.push({
        open: false,
        width: '80%',
        anchor: EDrawerAnchor.left,
        bg: '#171A1F',
        content: actions.payload,
      });
    },
    bottomDrawer: (state, actions) => {
      state.drawers.push({
        open: false,
        width: '100%',
        anchor: EDrawerAnchor.bottom,
        bg: 'none',
        content: actions.payload,
      });
    },
    hideDrawer: (state) => {
      const index = state.drawers.findLastIndex(e => e.open);
      state.drawers[index <= 0 ? 0 : index].open = false;
      return state;
    },
    showDrawer: (state) => {
      state.drawers[state.drawers.length - 1].open = true;
      return state;
    },
  }
});

export const { 
  fullDrawer,
  leftDrawer,
  bottomDrawer,
  showDrawer,
  hideDrawer,
} = slice.actions;

export default slice.reducer;
