import OptionForm from 'components/Helper/OptionForm';
import Percentage from 'components/Helper/Percentage';
import config from 'config';
import useUser from 'hooks/useUser';
import { IFormProps } from 'interfaces/send';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter, getPercent } from 'utils/numeral';

const Form = ({ data, setIsForm, setId }: IFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { balance } = useUser();
  const [percentage, setPercentage] = useState(0);
  const [network, setNetwork] = useState('');
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState('');

  const wallet = balance.wallet[data.symbol!];
  const detail = data.networks.find(e => e.symbol === network);

  const start = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/capital/send`, true).post({
      coin: data.symbol,
      amount: amount,
      to: address,
      network: network,
    });

    if (result.code === 200) {
      setIsForm(false);
      setId(result.data.id);
      dispatch(errorAlert('Code: ' + result.data.code));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const paste = async () => {
    try {
      setAddress(await navigator.clipboard.readText());
      dispatch(successAlert(t('pastedSuccessful')));
    } catch (e) {
      console.log('paste', e);
    }
  }
  
  useEffect(() => {
    if (getPercent(wallet?.free, percentage) != amount) {
      setPercentage(0);
    }
  }, [amount]);

  useEffect(() => {
    percentage > 0 && setAmount(getPercent(wallet?.free, percentage));
  }, [percentage]);

  useEffect(() => {
    setNetwork('');
    setAmount(0);
    setPercentage(0);
  }, [data])

  return (
    <>
      <div className='setNow'>
        <p>{t('asset')}:</p>
        <span onClick={() => setPercentage(100)}>≈ {formatter(wallet?.free! || 0)} {data?.symbol}</span>
      </div>
      <div className='box boxMarket boxSingle'>
        <p>{t('enterCryptoAmount')}</p>
        <NumericFormat value={amount > 0 ? amount : ''} thousandSeparator={true} onValueChange={(e) => setAmount(Number(e.value))} placeholder='0.00' />
      </div>
      <Percentage percentage={percentage} setPercentage={setPercentage} />
      <div className='box boxMarket boxReceive' onClick={() => dispatch(bottomDrawer(<OptionForm value={data?.networks.map((e) => e.symbol).reduce((ac, a) => ({ ...ac, [a]: a }), {})} def={network} setOption={setNetwork} />))}>
        {!network ? t('selectNetwork') : network}
      </div>
      <div className='infoData'>
        {detail && (
          <>
            <span><p>{t('minTransaction')}</p><i>{formatter(0)}</i></span>
            <span><p>{t('maxTransaction')}</p><i>{formatter(0)}</i></span>
            <span><p>{t('exchangeRate')} (Fee)</p><i>{formatter(0)}</i></span>
          </>
        )}
      </div>
      <div className='showData'>
        <div className='dataShare'>
          <div className='box boxMarket qrAddress'>
            <span onClick={() => paste()} className='paste'></span>
            <input placeholder={`${t('walletAddress')}`} onChange={(e) => setAddress(e.target.value)} defaultValue={address} />
          </div>
        </div>
        <div className='share' onClick={() => start()}>{t('receiveCode')}</div>
      </div>
    </>
  )
}

export default Form;
