import Back from 'components/Icons/Back';
import config from 'config';
import { ICheckPassword } from 'interfaces/login';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { getUserData } from 'utils/user';

const SetPassword = ({ setStep, token }: ICheckPassword) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [repasswordView, setRepasswordView] = useState(false);
  const dispatch = useDispatch();

  const send = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/security/login/check`).post({
      type: 'text',
      password: password,
      repassword: repassword,
      token: token,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(4);

      Cookies.set('accessToken', result.data.tokens.access, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('refreshToken', result.data.tokens.refresh, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('socketToken', result.data.tokens.socket, { expires: 30 * 24 * 60 * 60, path: '/' });
      
      await getUserData(dispatch);
      
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('passwordCreate')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='showData'>
        <p className='textInCard'>{t('passwordInfo')}</p>
        <div className='loginContent verifyForm'>
          <div className='parentPassword'>
            <input onChange={(e) => setPassword(e.target.value)} className='ltr' placeholder={`${t('password')}`} type={!passwordView ? 'password' : 'text'} />
            <i className={passwordView ? 'active' : ''} onClick={() => setPasswordView(!passwordView)}></i>
          </div>
          <div className='passwordBullet'>
            <i className={(password.length >= 8) ? 'active' : ''}>{ t('passwordCon1') }</i>
            <i className={(/[0-9]/.test(password) && /[A-Za-z]/.test(password)) ? 'active' : ''}>{t('passwordCon2')}</i>
          </div>
          <div className='parentPassword'>
            <input onChange={(e) => setRepassword(e.target.value)} className='ltr' placeholder={`${t('repassword')}`} type={!repasswordView ? 'password' : 'text'} />
            <i className={repasswordView ? 'active' : ''} onClick={() => setRepasswordView(!repasswordView)}></i>
          </div>
        </div>
        <div className='infoText'>
          <span></span>
          <p>{t('forSecurity')}</p>
        </div>
        <div className='lineSep lineSepTop'></div>
      </div>
      <div className='share' onClick={() => send()}>{t('confirm')}</div>
    </div>
  );
}

export default SetPassword;
