import Tabs from 'components/Helper/Tabs';
import Back from 'components/Icons/Back';
import config from 'config';
import { ETab } from 'enums/deposit';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { bottomDrawer, hideDrawer } from 'services/reducer/drawer';
import Card from './Card';
import Online from './Online';

const Deposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [newCard, setNewCard] = useState('');
  const [tab, setTab] = useState(ETab.online);
  const { cards } = useUser();

  const tabTitle = {
    [ETab.online]: t('gatewayPayment'), 
    // [depositTabEnum.card]: t('cardToCard'), 
  }

  const openCard = () => {
    const rows: React.ReactElement[] = []
    
    if (Object.keys(cards).length > 0) {
      Object.values(cards).forEach((value: any) => {
        rows.push(
        <span className={value.number == newCard ? 'active' : ''} key={value.number} onClick={() => { setNewCard(value.number); dispatch(hideDrawer()); }}>
          <i>{value.number.match(new RegExp('.{1,4}', 'g')).join('-')}</i>
          {value.bank && <img src={config.api + '/banks/' + value.bank.toLowerCase() + '.png'} alt='' /> }
        </span>
        );
      });

      dispatch(bottomDrawer(
        <div className={'popup popupDown optionList optionCardList'}>
          {rows}
        </div>
      ))
    }
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('chargeAccount')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
        <div className='twoBoxLine'>
          <Tabs titles={tabTitle} selected={tab} setSelected={setTab}/>
        </div>
        <div className='box boxMarket boxSingle boxCharge'>
          <NumericFormat value={amount > 0 ? amount : ''} thousandSeparator={true} onValueChange={(e) => setAmount(Number(e.value))} placeholder={`${t('payAmount')}`} />
          <p>{t('toman')}</p>
        </div>
        <div className='box boxMarket boxReceive boxSelect' onClick={() => openCard()}>
          {!newCard ? (
            t('depositFrom')
          ) : (
            newCard.match(new RegExp('.{1,4}', 'g'))?.join('-')
          )}
        </div>
        {tab === ETab.card ? (
          <Card amount={amount} card={newCard} />
        ) : (
          <Online amount={amount} card={newCard} />
        )}
      </div>
    </div>
  )
}

export default Deposit;
