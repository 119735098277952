import { CircularProgress } from '@mui/material';
import config from 'config';
import { ISlider } from 'interfaces/home';
import { useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import gather from 'utils/gather';

const Slider = () => {
  const [slider, setSlider] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = () => {
    const rows: React.ReactElement[] = [];

    slider.forEach((element: ISlider, i) => {
      rows.push(
        <SwiperSlide key={i}>
          <a href={element.link} rel='noreferrer' target='_blank' className='inSlide'>
            <div>
              <h3>{element.title}</h3>
              <div className='price'>
                <i>{element.text}</i>
              </div>
              <p>
                {element.button}
                <i></i>
              </p>
            </div>
            <img src='/images/tether.png' alt='' />
          </a>
        </SwiperSlide>,
      );
    });

    return rows;
  };

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/data/banner`).get();
    if (result.code === 200) {
      setSlider(result.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  return (
    <div className='slide'>
      {loading ? (
        <div className='loadingParent'>
          <CircularProgress size={20} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: '#fff',
              fontSize: '22px',
              direction: 'ltr',
              gap: '15px',
            }}
          >
            1,300,000 SYP<i style={{ direction: 'ltr' }}>99.98 USD</i>
          </div>
          <Swiper style={{ display: 'none' }} modules={[Pagination]} slidesPerView={1} pagination={{ clickable: true }}>
            {list()}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default Slider;
