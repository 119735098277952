import Back from 'components/Icons/Back';
import useGlobal from 'hooks/useGlobal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Send from './Send';

const Convert = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [to, setTo] = useState('');
  const [from, setFrom] = useState(value);
  const { coins } = useGlobal();

  const dataFrom = coins[from];
  const dataTo = coins[to];
  
  useEffect(() => {
    setAmount(0);
  }, [from]);
  
  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('exchange')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
        <Form dataFrom={dataFrom} dataTo={dataTo} setFrom={setFrom} setTo={setTo} setAmount={setAmount} amount={amount} />
        <Send dataFrom={dataFrom} dataTo={dataTo} amount={amount} />
      </div>
    </div>
  )
}

export default Convert;
