import config from 'config';
import { t } from 'i18next';
import { IDispatch } from 'interfaces/global';
import Cookies from 'js-cookie';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { updateTicker } from 'services/reducer/ticker';
import { updateAsset, updateBalance, updateLevel } from 'services/reducer/user';
import { Socket, io } from 'socket.io-client';
import { formatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

let socket: Socket;
let time: Date = new Date();

const connect = (dispatch: IDispatch) => {
  if (!socket?.connected) {
    const socketToken = isUserLogin() ? Cookies.get('socketToken')! : '';

    socket = io(config.api, {
      extraHeaders: {
        authorization: 'Bearer ' + socketToken,
      },
    });

    if (new Date().getTime() - time.getTime() >= 20 * 1000){
      window.location.reload();
    }
  }

  socket.on('connect', () => {
    console.log('socket', 'Connected');
  });

  socket.on('disconnect', () => {
    console.log('socket', 'Disconnected');
    time = new Date();
    setTimeout(() => {
      connect(dispatch);
    }, 100)
  });

  socket.on('connect_failed', () => {
    console.log('socket', 'Failed');
  })

  socket.on('connect_error', (e) => {
    console.log('socket', 'Error: ' + e);
  })

  socket.on('ticker', (result) => {
    dispatch(updateTicker(result));
  });

  socket.on('balance', (result) => {
    console.log('BALANCE');
    dispatch(updateBalance(result));
  });

  socket.on('asset', (result) => {
    dispatch(updateAsset(result));
  });

  socket.on('notif', (result) => {
    console.log(result);
    if (result.type === 'error') {
      dispatch(errorAlert(t(result.message)));
    } else {
      dispatch(successAlert(t(result.message)));
    }
  });

  socket.on('alarm', (result) => {
    dispatch(successAlert(result.message + ' | ' + result.symbol + ' | ' + formatter(result.value)));
  })

  socket.on('system', (result) => {
    if (result.part === 'support') {
      if (result.message === 'close') {
        const find = document.getElementById('system_chat');
        if (find) {
          console.log('CLOSE');
          // dispatch(hideLoading());
        }
      }
    }

    if (result.part === 'level') {
      dispatch(updateLevel(result.message));
      // dispatch(profile());
    }
  });
}

const reconnect = (dispatch: IDispatch) => {
  socket.disconnect();
  connect(dispatch);
}

const disconnect = () => {
  socket.disconnect();
}

export {
  connect,
  disconnect,
  reconnect,
  socket
};
