import { CircularProgress } from '@mui/material';
import config from 'config';
import { EStatus } from 'enums/global';
import { IOrder } from 'interfaces/trade';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { socket } from 'services/socket';
import { statusColor, typeColor } from 'utils/color';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const OpenOrder = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState<IOrder[]>([]);
  const dispatch = useDispatch();

  const cancel = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/capital/order/${id}`, true).delete();
    if (result.code === 200) {
      dispatch(successAlert(t('orderUnsuccessful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const list = () => {
    const rows: React.ReactElement[] = [];
    
    if (orderList.length > 0) {
      orderList.forEach((value, i) => {
        rows.push(
          <div className='itemTrade' key={i}>
            <span>{moment(value.createdAt).locale('fa').format('YYYY-MM-DD HH:mm:ss')}</span>
            <div>
              <span>Spot</span>
              <span className={`color${typeColor(value.type)}`}>{t(value.type)}</span>
              <span>{value.pair}</span>
              {value.status === EStatus.pending ? (
                <div className='cancel' onClick={() => cancel(value.id)}>{t('cancel')}</div>
              ) : (
                <div className={`color${statusColor(value.status)}`}>{t(value.status)}</div>
              )}
            </div>
            <div>
              <span>{t('price')}</span>
              <span>{autoFormatter(value.price)}</span>
            </div>
            <div>
              <span>{t('amount')}</span>
              <span>{autoFormatter(value.amount)}</span>
            </div>
            <div>
              <span>{t('sum')}</span>
              <span>≈ {autoFormatter(value.total)}</span>
            </div>
          </div>
        )
      });
    } else {
      rows.push(<span key={1000} className='notifData notFound'>{t('notFound')}</span>);
    }

    return rows;
  }

  const newOrder = (result: IOrder) => {
    setOrderList(state => {
      const index = state.findIndex(e => e.id === result.id);
      if (index >= 0) {
        const updatedState = [...state];
        updatedState[index] = result;
        return updatedState;
      }
      return [result, ...state];
    });
  }

  const getData = async () => {
    setLoading(true);

    const result = await gather(`${config.api}/api/v1/capital/order`, true).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (isUserLogin()) {
      getData();
    } else {
      setLoading(false);
    }
    
    socket.on('newOrder', newOrder);
    return () => {
      socket.removeListener('newOrder', newOrder);
    }
  }, []);

  return (
    <div className='infoCurrency'>
      <div className='infoTab'>
        <span>{t('openOrders')}</span>
      </div>
      <div className='historyTrade'>
        {loading ? (
          <div className='loadingParent'><CircularProgress size={20} /></div>
        ) : (
          list()
        )}
      </div>
    </div>
  );
}

export default OpenOrder;
