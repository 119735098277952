import { ICardProps } from 'interfaces/wallet';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { statusColor } from 'utils/color';
import { formatter } from 'utils/numeral';

const Convert = ({ value }: ICardProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h3><b className={`color${statusColor(value.status)}`}>{t(value.type)}</b></h3>
        <p>{formatter(value.amount)} {value.from}</p>
      </div>
      <div>
        <b><i>{t('to')}</i> {formatter(value.receive)} {value.to}</b>
        <b><i>{moment(value.createdAt).locale('fa').format('DD MMM , HH:mm')}</i></b>
      </div>
    </>
  );
}

export default Convert;
