export enum ETab {
  swing = 'swing',
  price = 'price',
}

export enum EKind {
  up = 'up',
  down = 'down',
  equal = 'equal',
}
