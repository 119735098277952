import Back from 'components/Icons/Back';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordView, setOldPasswordView] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [repasswordView, setRepasswordView] = useState(false);
  const dispatch = useDispatch();

  const sendData = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/security/password`, true).put({
      type: 'text',
      oldPassword: oldPassword,
      password: password,
      repassword: repassword,
    });

    if (result.code === 200) {
      dispatch(successAlert(t('editSuccessful')));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('passwordLogin')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='showData'>
        <div className='loginContent verifyForm'>
          <div className='parentPassword'>
            <p>{t('oldPassword')}</p>
            <input onChange={(e) => setOldPassword(e.target.value)} className='ltr' placeholder={`${t('oldPassword')}`} type={!oldPasswordView ? 'password' : 'text'} />
            <i className={oldPasswordView ? 'active' : ''} onClick={() => setOldPasswordView(!oldPasswordView)}></i>
          </div>
          <br/>
          <div className='parentPassword'>
            <p>{t('newPassword')}</p>
            <input onChange={(e) => setPassword(e.target.value)} className='ltr' placeholder={`${t('password')}`} type={!passwordView ? 'password' : 'text'} />
            <i className={passwordView ? 'active' : ''} onClick={() => setPasswordView(!passwordView)}></i>
          </div>
          <div className='passwordBullet'>
            <i className={ (password.length >= 8) ? 'active' : '' }>{ t('passwordCon1') }</i>
            <i className={ (/[0-9]/.test(password) && /[A-Za-z]/.test(password)) ? 'active' : '' }>{ t('passwordCon2') }</i>
          </div>
          <div className='parentPassword'>
            <input onChange={(e) => setRepassword(e.target.value)} className='ltr' placeholder={`${t('repassword')}`} type={!repasswordView ? 'password' : 'text'} />
            <i className={repasswordView ? 'active' : ''} onClick={() => setRepasswordView(!repasswordView)}></i>
          </div>
        </div>
      </div>
      <div className='share' onClick={() => sendData()}>{ t('submit') }</div>
    </div>
  )
}

export default ChangePassword;
