import Back from 'components/Icons/Back';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import ChangePassword from './ChangePassword';

const Security = () => {
  const { t } = useTranslation();
  // const { profile } = useUser();
  const dispatch = useDispatch<any>();

  // const openPage = (_value: string, isActive: boolean) => {
  //   // if (value === passwordEnum.pattern) {
  //     if (isActive) {
  //       deletePattern()
  //     } else {
  //       // dispatch(fullDrawer(<Pattern />))
  //     }
  //   // }
  // }

  // const deletePattern = async () => {
  //   dispatch(showLoading());

  //   const result = await gather(`${config.api}/user/pattern`, true).delete();
  //   if (result.code === 200) {
  //     await dispatch(getProfile());
  //   } else {
  //     dispatch(errorAlert(t(result?.message) || t('errorFound')));
  //   }

  //   dispatch(hideLoading());
  // }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('security')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='settingList'>
        <span onClick={() => dispatch(fullDrawer(<ChangePassword />))}><i className='iconPass'></i>{ t('changePassword') }</span>
      </div>
      <div className='passwordList'>
        {/* {listCheck()} */}
      </div>
      <div className='infoText'>
        <p>{t('passworrdInfo')}</p>
      </div>
    </div>
  )
}

export default Security;
