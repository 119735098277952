import Percentage from 'components/Helper/Percentage';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { IForm } from 'interfaces/withdraw';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { bottomDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter, getPercent } from 'utils/numeral';

const Form = ({ setIsForm, symbol, setId }: IForm) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { levels } = useGlobal();
  const { balance, cards, profile } = useUser();
  const [percentage, setPercentage] = useState(0);
  const [newCard, setNewCard] = useState('');
  const [amount, setAmount] = useState(0);
  
  const wallet = balance.account[symbol];

  const openCard = () => {
    const rows: React.ReactElement[] = []
    
    if (Object.keys(cards).length > 0) {
    Object.values(cards).forEach((value: any) => {
      rows.push(
      <span className={value.number == newCard ? 'active' : ''} key={value.number} onClick={() => { setNewCard(value.number); dispatch(hideDrawer()); }}>
        <i>{value.number.match(new RegExp('.{1,4}', 'g')).join('-')}</i>
        {value.bank && <img src={config.api + '/banks/' + value.bank.toLowerCase() + '.png'} alt='' /> }
      </span>
      );
    });

    dispatch(bottomDrawer(
      <div className={'popup popupDown optionList optionCardList'}>
        {rows}
      </div>
    ))
    }
  }

  const send = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.api}/api/v1/account/withdraw`, true).post({
      currency: symbol,
      amount: amount,
      card: newCard,
    });
    
    dispatch(hideLoading());

    if (result.code === 200) {
      setIsForm(false);
      setId(result.data.id);
      dispatch(errorAlert(result.data.code));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  useEffect(() => {
    if (getPercent(wallet?.free, percentage) != amount) {
      setPercentage(0);
    }
  }, [amount]);

  // useEffect(() => {
  //   if (getPercent(wallet?.free, percentage) != amount) {
  //     setPercentage(0);
  //   }
  // }, [amount]);

  useEffect(() => {
    percentage > 0 && setAmount(getPercent(wallet?.free, percentage));
  }, [percentage]);

  return (
    <>
      <div className='box boxMarket boxSingle'>
        <p>{t('withdrawAmountTo')} {t(symbol)}</p>
        <NumericFormat value={amount > 0 ? amount : ''} allowNegative={false} thousandSeparator={true} onValueChange={(e) => setAmount(Number(e.value))} placeholder='0.00' />
      </div>
      <Percentage percentage={percentage} setPercentage={setPercentage}/>
      <div className='setNow'>
        <p>{t('asset')}:</p>
        <span onClick={() => setPercentage(100)}>≈ {formatter(wallet?.free || 0)} {wallet?.symbol}</span>
      </div>
      <div className='box boxMarket boxReceive boxSelect' onClick={() => openCard()}>
        {!newCard ? (
          t('withdrawTo')
        ) : (
          newCard.match(new RegExp('.{1,4}', 'g'))?.join('-')
        )}
      </div>
      <div className='infoText errorText'>
        <span></span>
        <p>{t('minWithdraw')}: {formatter(levels[profile.level]?.fee?.currency?.[0]?.withdraw?.min || 0)}</p>
      </div>
      <div className='infoText errorText'>
        <span></span>
        <p>{t('bankRule')}</p>
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => send()}>{t('withdrawReqest')}</div>
      </div>
    </>
  )
}

export default Form;
