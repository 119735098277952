export enum ETab {
  buySell = 'buySell',
  sendReceive = 'sendReceive',
  convert = 'convert',
  deposit = 'deposit',
  withdraw = 'withdraw',
}

export enum EMonth {
  m1 = 1,
  m3 = 3,
  m6 = 6,
}
