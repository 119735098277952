import { ESuccess } from 'enums/payment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

const Payment = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const success = (searchParams.get('success') as ESuccess) ?? ESuccess.false;

  useEffect(() => {
    document.documentElement.setAttribute('id', 'paymentId');
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <div className='doneData popupFix'>
        <div className='fullBox'>
          {success === ESuccess.card ? (
            <>
              <div className='doneIcon cardIcon'></div>
              <h3>{t('receivedForm')}</h3>
              <p>{t('receivedFormInfo')}</p>
            </>
          ) : success === ESuccess.true ? (
            <>
              <div className='doneIcon'></div>
              <h3>{t('successPayment')}</h3>
              <h6>{t('chargedAccount')}</h6>
              <p>{t('chargedAccountInfo')}</p>
            </>
          ) : (
            <>
              <div className='doneIcon errorIcon'></div>
              <h3>{t('unsuccessPayment')}</h3>
              <p>{t('unsuccessPaymentInfo')}</p>
            </>
          )}
        </div>
        <Link to={'/'} className='share'>{t('backToDex')}</Link>
      </div>
    </>
  );
}

export default Payment;
