import NoBalance from 'components/Layouts/NoBalance';
import config from 'config';
import { IVerifyProps } from 'interfaces/send';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { bottomDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Verify = ({ id }: IVerifyProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [code, setCode] = useState('');

  const start = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.api}/api/v1/capital/send/code`, true).post({
      id: id,
      code: code,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert(t('submitSuccessful')));
      dispatch(hideDrawer());
    } else if(result.message === '90021'){
      dispatch(bottomDrawer(<NoBalance />))
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }
  
  return (
    <>
      <div className='box boxMarket boxSingle'>
        <p>{t('enterVerifyCode')}</p>
        <input placeholder='- - - - -' value={code} onChange={(e) => setCode(e.target.value)} />
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => start()}>{t('submit')}</div>
      </div>
    </>
  )
}

export default Verify;
