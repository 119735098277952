import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Verify = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  
  const verify = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.api}/api/v1/account/withdraw/code`, true).post({
      code: code,
      id: id,
    });
    
    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert(t('submitSuccessful')));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  return (
    <>
      <div className='box boxMarket boxSingle'>
        <p>{t('enterVerifyCode')}</p>
        <input placeholder='- - - - -' value={code} onChange={(e) => setCode(e.target.value)} />
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => verify()}>{t('submit')}</div>
      </div>
    </>
  )
}

export default Verify;
