import config from 'config';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Delete = ({ setNotifList }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const yes = () => {
    gather(`${config.api}/api/v1/live/notif`, true).delete();

    setNotifList([]);

    dispatch(hideDrawer());
    dispatch(successAlert(t('deleteAlarmSuccessful')));
  }

  return (
    <div className='popup popupDown deleteNotif'>
      <div className='alertIcon'>
        <span></span>
        <p>{t('deleteAlarm')}</p> 
      </div>
      <p className='alertText'>{t('deleteAlarmInfo')}</p>
      <section>
        <div className='share' onClick={() => yes()}>{t('yes')}</div>
        <div className='share' onClick={() => dispatch(hideLoading())}>{t('no')}</div>
      </section>
    </div>
  )
}

export default Delete;
