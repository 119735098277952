import { CircularProgress } from '@mui/material';
import DatePicker from 'components/Helper/DatePicker';
import Back from 'components/Icons/Back';
import config from 'config';
import { ETab } from 'enums/bill';
import { ETransaction } from 'enums/global';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';
import Data from './Data';
import ListTab from './ListTab';

const Bill = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(ETab.all);
  const [filter, setFilter] = useState();
  const [from, setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  
  const setNewFrom = async (value: string) => {
    setFrom(Math.floor(moment.from(value+' 00:00:00', 'fa', 'YYYY-MM-DD HH:mm:ss').valueOf()))
  }

  const setNewTo = async (value: string) => {
    setTo(Math.floor(moment.from(value+' 23:59:59', 'fa', 'YYYY-MM-DD HH:mm:ss').valueOf()))
  }

  const getData = async (address: string, tab: ETab, type?: ETransaction) => {
    const result = await gather(`${config.api}/api/v1/${address}?from=${from}&to=${to}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) => Object.assign({ tab: tab, [type ? 'type' : '']: type }, e));
    }
    return [];
  }

  const getList = async () => {
    setLoading(true);
    setList([]);
    
    const result = await Promise.all([
      getData('capital/send', ETab.transaction, ETransaction.send),
      getData('capital/receive', ETab.transaction, ETransaction.receive),
      getData('capital/convert', ETab.transaction, ETransaction.convert),
      getData('account/deposit', ETab.transaction, ETransaction.deposit),
      getData('account/withdraw', ETab.transaction, ETransaction.withdraw),
      getData('capital/order', ETab.trade),
    ]).then((results) => {
      return [].concat.apply([], [results[0], results[1], results[2], results[3], results[4], results[5]]);
    });

    setLoading(false);
    setList(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
  }

  useEffect(() => {
    getList();
  }, [from, to]);

  return (
    <div className='popup'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('bills')}</h2></span>
          <div className='notifIcon calendarIcon' onClick={() => dispatch(bottomDrawer(<DatePicker isRange={true} defaultFrom={moment(from).locale('fa').format('YYYY-MM-DD')} defaultTo={moment(to).locale('fa').format('YYYY-MM-DD')} setDateFrom={setNewFrom} setDateTo={setNewTo} />))}></div>
        </div>
      </div>
      {loading ? (
        <div className='loadingParent'>
          <CircularProgress color='info' size={30} />
        </div>
      ) : (
        <>
          <ListTab setTab={setTab} tab={tab} setFilter={setFilter} filter={filter} />
          <Data list={list} tab={tab} filter={filter} />
        </>
      )}
    </div>
  )
}

export default Bill;
