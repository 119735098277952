import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter } from 'utils/numeral';

const Card = ({ amount, card }: { amount: number, card: string}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencyUnit } = useGlobal();
  const [_trans, setTrans] = useState('')
  const [_bank, setBank] = useState('')

  const start = async () => {
    dispatch(showLoading());

    const result = await gather(config.pwa+'/payment/deposit', true).post({
      card: card,
      amount: amount,
      currency : currencyUnit,
      provider: 'manual',
      callback: config.pwa + '/payment?success=true',
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      navigate('/payment?success=card', { replace: true });
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }
  
  return (
    <>
      {amount > 0 && (
        <div className='bankCard'>
          {t('amountDeposit')}: {formatter(amount)}<br/>
          <i>9000-2000-3000-5000</i>
          <p>{t('nameForCardToCard')}</p>
        </div>
      )}
      <div className='loginContent verifyForm'>
        <input onChange={(e) => setBank(e.target.value)} placeholder={`${t('fromBank')}`} type='text' />
        <input onChange={(e) => setTrans(e.target.value)} placeholder={`${t('transactionData')}`} type='text' />
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => start()}>{t('sendMoneyData')}</div>
      </div>
    </>
  )
}

export default Card;
