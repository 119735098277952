import Tabs from 'components/Helper/Tabs';
import config from 'config';
import { ETab } from 'enums/levelUp';
import useTimer from 'hooks/useTimer';
import { IStep } from 'interfaces/levelUp';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useRecordWebcam } from 'react-record-webcam';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Selfie = ({ setStep }: IStep) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.picture);
  const recordWebcam = useRecordWebcam();
  const [src, setSrc] = useState();
  const dispatch = useDispatch();
  const timer = useTimer();
  
  const tabTitle = {
    [ETab.video]: t('selfieVideo'),
    [ETab.picture]: t('selfiePic'),
  }

  const uploadImage = async (file: any) => {
    dispatch(showLoading());

    const formData = new FormData();
    formData.append('type', 'selfie')
    formData.append('doc', file);
    
    const result = await gather(`${config.api}/api/v1/account/file`, true).upload(formData);
    if (result.code === 200) {
      setSrc(result.data.link);
      dispatch(successAlert(t('uploadCompleted')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  };

  const uploadVideo = async () => {
    dispatch(showLoading());

    const blob = await recordWebcam.getRecording()
    const file = new File([blob!], 'filename.mp4', { type: 'video/mp4' });
    
    const formData = new FormData();
    formData.append('type', 'clip')
    formData.append('doc', file);
    
    const result = await gather(`${config.api}/api/v1/account/file`, true).upload(formData);
    if (result.code === 200) {
      recordWebcam.close();
      setTimeout(() => {
        setStep(3);
      }, 10);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    recordWebcam.close();
    if (tab === ETab.video){
      recordWebcam.open();
    }
  }, [tab]);

  useEffect(() => {
    return () => {
      recordWebcam.close();
    }
  }, []);

  return (
    <>
      <div className='currencyTitle selfieTitle'>
        <Tabs titles={tabTitle} setSelected={setTab} selected={tab} />
      </div>
      {tab === ETab.picture ? (
        <>
          <div className='infoText'>
            <span></span>
            <p className='whiteText'>{t('textOnPaper')}</p>
          </div>
          <div className='selfiePicInfo'>
            <p>{t('textOnPaperInfo')}</p>
            <p>{t('nameAndFamily')}</p>
            <br/>
            <p>{t('signAndDate')}</p>
          </div>
          <div className='parentFixed'>
            <p>{t('uploadSelfie')}</p>
            <div className='uploadPicture fileUploader'>
              {src && (<img src={src} alt='' />)}
              <FileUploader handleChange={uploadImage} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
              <span></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`videoContainer videoProgress videoProgress_${recordWebcam.status}`}>
            <video playsInline={true} webkit-playsinline="true" disablePictureInPicture={true} ref={recordWebcam.previewRef} className='recordVideo' muted />
            <video playsInline={true} webkit-playsinline="true" disablePictureInPicture={true} ref={recordWebcam.webcamRef} className='recordVideo' autoPlay muted />
            <span><b>{timer.minute} : {timer.second}</b></span>
          </div>
          <div className='selfiePicInfo'>
            <p>{t('textOnPaperInfo')}</p>
          </div>
          <p className='whiteText'>{t('pressRecord')}</p>
          <div className='recordButtons'>
            {recordWebcam.status === 'INIT' ? (
              <div className='initing'>{t('initing')}</div>
            ) : recordWebcam.status === 'RECORDING' ? (
              <div className='button recording' onClick={() => { timer.stop(); recordWebcam.stop() }}>{t('stop')}</div>
            ) : recordWebcam.status === 'PREVIEW' ? (
              <>
                <div className='button preview' onClick={() => recordWebcam.previewRef.current?.play()}>{t('preview')}</div>
                <div className='button retry whiteFilter' onClick={() => recordWebcam.retake()}>{t('retry')}</div>
              </>
            ) : (
                <div className={'button open'} onClick={() => { timer.start(); recordWebcam.start() }}>{t('record')}</div>
            )}
          </div>
        </>
      )}
      <div className='bottomVerify'>
        <div className='share' onClick={() => tab === ETab.picture ? setStep(2) : uploadVideo()}>{t('addAndContinue')}</div>
      </div>
    </>
  );
}

export default Selfie;
