import { ICardProps } from 'interfaces/wallet';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { typeColor } from 'utils/color';
import { formatter } from 'utils/numeral';

const BuySell = ({ value }: ICardProps) => {
  const { t } = useTranslation();
  
  return (
    <>
      <div>
        <h3 className={`color${typeColor(value.type)}`}>{value.pair}</h3>
        <p>{formatter(value.total)}</p>
      </div>
      <div>
        <b><i>{t('price')}</i> {formatter(value.price)}</b>
        <b><i>{t('amount')}</i> {formatter(value.amount)}</b>
        <b><i>{moment(value.createdAt).locale('fa').format('DD MMM , HH:mm')}</i></b>
      </div>
    </>
  );
}

export default BuySell;
