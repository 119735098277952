import Back from 'components/Icons/Back';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import Code from './Code';
import Form from './Form';
import SetPassword from './SetPassword';

const Login = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1)
  const [mobile, setMobile] = useState('')
  const [token, setToken] = useState('')
  const [code, setCode] = useState<number[]>([])
  const dispatch = useDispatch();

  const auth = async () => {
    setCode([]);

    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/security/login`).post({
      mobile: mobile.startsWith('0') ? mobile.substring(1) : mobile,
      prefix: '+98',
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setCode((result.data.code).toString().split(''));
      setStep(2);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  return (
    <div className='popup popupFix popupRegister'>
      {step !== 4 && <Back />}
      <div className='fullBox'>
        <div className='content loginContent'>
          {step === 1 ? (
            <Form mobile={mobile} setMobile={setMobile} auth={auth} />
          ) : step === 2 ? (
            <Code mobile={mobile} setCode={setCode} setStep={setStep} code={code} setToken={setToken} auth={auth} />
          ) : step === 3 ? (
            <>
              <div className='logoPassword'></div>
              <h2>{t('security')}</h2>
              <div className='parentOfInput'>
                <p>{t('securityInfo')}</p>
              </div>
              <div className='share' onClick={() => dispatch(fullDrawer(<SetPassword token={token} setStep={setStep} />))}>{t('createPassword')}</div>
            </>
          ) : (
            <>
              <div className='doneIcon'></div>
              <div className='mainText'>
                <h3>{t('welcome')}</h3>
                <div>{t('welcomeInfo')}</div>
              </div>
              <div className='share' onClick={() => window.location.reload()}>{t('goToDex')}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login;
