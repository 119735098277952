import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPopupOpen: false,
  content: null,
}

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPopup: (state, actions) => {
      state.isPopupOpen = true;
      state.content = actions.payload;
    },
    hidePopup: (state) => {
      state.isPopupOpen = false;
    }
  }
});

export const { 
  showPopup, 
  hidePopup,
} = slice.actions;

export default slice.reducer;
