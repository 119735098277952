import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import Form from './two/Form';

const Level2 = () => {
  const { t } = useTranslation();
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className={`${isDone && 'doneData'} popup popupFix`}>
      {isDone ? (
        <>
          <div className='fullBox'>
            <div className='doneIcon'></div>
            <h3>{t('yourDataSaved')}</h3>
            <p>{t('yourDataSavedInfo')}</p>
          </div>
          <div className='bottomVerify'>
            <div className='share' onClick={() => dispatch(hideDrawer())}>{t('backToDex')}</div>
          </div>
        </>
      ) : (
        <Form setIsDone={setIsDone} />
      )}
    </div>
  )
}

export default Level2;
