import Back from 'components/Icons/Back';
import config from 'config';
import { ELevel } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { IForm } from 'interfaces/levelUp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gather from 'utils/gather';
import Address from './Address';
import Email from './Email';
import Selfie from './Selfie';

const Form = ({ setIsDone }: IForm) => {
  const { t } = useTranslation();
  const { levels } = useGlobal();
  const { profile } = useUser();

  const [step, setStep] = useState(profile.levelUp ? 3 : (profile.email ? 1 : 0));

  const data = Object.values(levels).find((e) => e.type === ELevel.l2);

  const list = () => {
    const rows: React.ReactElement[] = [];
    
    [t('addEmailAndConfirm'), t('uploadSelfiePicAndVid'), t('propertyDocuments')].forEach((value, key) => {
      rows.push(
        <div key={key} className={`${key <= step && 'active'}`}>
          <span>{t('step')} {(key + 1)}</span>
          <p>{value}</p>
        </div>
      );
    });

    return rows;
  }

  useEffect(() => {
    if (step === 3) {
      gather(`${config.api}/api/v1/account/user/upgrade`, true).post();
      setIsDone(true);
    }
  }, [step]);
  
  return (
    <>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('verifyLevel')} {data?.title}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='listOfLevel'>{list()}</div>
      <div className='showData'>
        {step === 0 ? <Email setStep={setStep} /> : step === 1 ? <Selfie setStep={setStep} /> : <Address setStep={setStep} />}
      </div>
    </>
  );
}

export default Form;
