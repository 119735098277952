import { ETransaction } from 'enums/global';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { cardColor, statusColor } from 'utils/color';
import BuySell from './content/BuySell';
import Convert from './content/Convert';
import DepositWithdraw from './content/DepositWithdraw';
import SendReceive from './content/SendReceive';

const Detail = ({ value }: any) => {
  const { t } = useTranslation();

  const findContent = (value: any) => {
    switch (value.type) {
      case ETransaction.deposit:
      case ETransaction.withdraw:
        return <DepositWithdraw value={value} />;

      case ETransaction.buy:
      case ETransaction.sell:
        return <BuySell value={value} />;

      case ETransaction.receive:
      case ETransaction.send:
        return <SendReceive value={value} />;
        
      default:
        return <Convert value={value} />;
    }
  }

  return (
    <div className={`popup popupDown deleteNotif confirmOrder block${cardColor(value.type, value.status)} detail_${value.type}`}>
      <div className='headConfirm'>
        <div className={`color${cardColor(value.type, value.status)}`}>
          <span className={`confirmType confirmType${cardColor(value.type, value.status)}`}></span>
          {t(value.type)} {value.type === 'withdraw' && t('fromDex')}
        </div>
        {value.symbol && (<h6>{value.symbol}</h6>)}
      </div>
      <div className='bodyConfirm'>
        <span><i>{t('date')}</i><b>{moment(value.createdAt).locale('fa').format('HH:mm:ss YYYY-MM-DD')}</b></span>
        <span><i>{t('status')}</i><b className={`color${statusColor(value.status)}`}>{t(value.status)}</b></span>
        {findContent(value)}
      </div>
    </div>
  );
}

export default Detail;
