export enum ETicker {
  price = 'price',
  low = 'low',
  high = 'high',
  change = 'change',
  volume = 'volume',
  value = 'value',
}

export enum EStatus {
  pending = 'pending',
  complete = 'complete',
  cancel = 'cancel',
  reject = 'reject',
  confirm = 'confirm',
  partial = 'partial',
  uphold = 'uphold',
}

export enum EColor {
  orange = 'Orange',
  gray = 'Gray',
  green = 'Green',
  red = 'Red',
  blue = 'Blue',
  light = 'Light',
}

// export enum EOrder {
//   buy = 'buy',
//   sell = 'sell',
// }

// export enum EPayment {
//   withdraw = 'withdraw',
//   deposit = 'deposit',
//   exchange = 'exchange',
// }

// export enum EWallet {
//   receive = 'receive',
//   send = 'send',
//   convert = 'convert',
// }

export enum ETransaction {
  buy = 'buy',
  sell = 'sell',
  withdraw = 'withdraw',
  deposit = 'deposit',
  receive = 'receive',
  send = 'send',
  convert = 'convert',
  exchange = 'exchange',
}

export enum EPairModel {
  coin = 'coin',
  currency = 'currency',
}

export enum EMode {
  limit = 'limit',
  market = 'market',
}

export enum ELevel {
  l0 = 0,
  l1 = 1,
  l2 = 2,
  l3 = 3,
  l4 = 4,
}
