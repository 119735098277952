const formatter = (price: number, max: number = 0, min: number = 0) => {
  return price.toLocaleString('en-US', {
    minimumFractionDigits: min, 
    maximumFractionDigits: max,
  });
}

const autoFormatter = (price: number) => {
  let max = 0;

  if (price >= 50000) {
    max = 0;
  } else if (price >= 1000) {
    max = 1;
  } else if (price >= 100) {
    max = 2;
  } else if (price >= 1) {
    max = 3;
  } else {
    const demical = price.toString().split('.')[1];
    if (demical) {
      max = Number(demical.match(/^0*/)?.[0].length) + 3;
    }
  }

  return formatter(price, max, 0);
}

const getPercent = (v: number, d: number) => {
  return Number(v * d / 100);
}

const getRandomNumber = (min: number, max: number, decimals: number) => {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
}

const toFixed = (number: number, digits: number = 8) => {
  return number;
  const adjust = Math.pow(10, digits)
  return Math.floor(number * adjust) / adjust;
}

export {
  autoFormatter,
  formatter,
  getPercent,
  getRandomNumber, toFixed
};
