import moment from 'jalali-moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer, hideDrawer } from 'services/reducer/drawer';
import OptionForm from './OptionForm';

type options = {
  setDatePicker?: any,
  setDateFrom?: any,
  setDateTo?: any,
  defaultValue?: string,
  defaultFrom?: string,
  defaultTo?: string,
  isRange?: boolean,
  format?: string,
}

const DatePicker = ({ setDatePicker, defaultValue, defaultFrom, defaultTo, isRange, setDateFrom, setDateTo }: options) => {
  const { t } = useTranslation();
  const [day, setDay] = useState('')
  const [dayFrom, setDayFrom] = useState('')
  const [dayTo, setDayTo] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState(0)

  const dispatch = useDispatch();

  const maxYear = Number(moment().locale('fa').format('YYYY'))
  
  const monthsRows = new Map();
  for (let i = 0; i < 12; i++) {
    const key = moment().locale('fa').month(i).format('MM');
    monthsRows.set(key, moment().locale('fa').month(i).format('MMM'))
  }

  const yearsRows: any = {}
  for (let i = 1320; i <= maxYear; i++) {
    yearsRows[i] = i
  }

  const daysRows = () => {
    const rows: React.ReactElement[] = []
    const daysName = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']

    daysName.forEach((name: string, i: number) => {
      rows.push(<span key={i}>{name}</span>)
    });

    return rows
  }

  const selectDay = (key: string) => {
    if (isRange) {
      if (!dayFrom) {
        setDayFrom(key)
      } else if (!dayTo) {
        if (key >= dayFrom) {
          setDayTo(key)
        }
      } else {
        setDayFrom('')
        setDayTo('')
      }
    } else {
      setDay(key)
    }
  }
  
  const days = () => {
    const daysInMonth = moment(year + '-' + month, 'jYYYY-jMM').locale('fa').daysInMonth();
    const startOfMonth = moment(year + '-' + month, 'jYYYY-jMM').locale('fa').startOf('month').day();

    const rows = []
    for (let i = 1; i <= startOfMonth + 1; i++) {
      rows.push(<span key={1000 + i}>&nbsp;</span>)
    }
    
    for (let i = 1; i <= daysInMonth; i++) {
      const key = ('0' + i).slice(-2)
      const current = year + '-' + month + '-' + key

      let style = (isRange ? (current === dayFrom || current === dayTo) : (key === day)) ? 'active' : ''
      if (isRange && current > dayFrom && current < dayTo) {
        style += ' activeIn';
      }
      if (isRange && current < dayFrom && dayFrom) {
        style += ' deactive';
      }
      if (isRange && current === dayFrom) {
        style += ' activeFrom';
      }
      if (isRange && current === dayTo) {
        style += ' activeTo';
      }
      if (!isRange && key === day) {
        style += ' activeFrom activeTo';
      }
      
      rows.push(<span key={i} className={style} onClick={() => selectDay(isRange ? year + '-' + month + '-' + key : key)}>{i}</span>)
    }
    return rows
  }

  const done = () => {
    if (isRange) {
      if (!dayFrom || !dayTo) {
        return
      }
      setDateFrom(dayFrom)
      setDateTo(dayTo)
    } else {
      if (!year || !month || !day) {
        return
      }
      setDatePicker(year + '-' + month + '-' + day)
    }

    dispatch(hideDrawer());
  }

  useEffect(() => {
    if (defaultFrom || defaultTo) {
      if (defaultFrom) {
        const value = defaultFrom?.split('-')

        setYear(Number(value![0]))
        setMonth(value![1])
        setDayFrom(defaultFrom)
      }

      if (defaultTo) {
        const value = defaultTo?.split('-')

        setYear(Number(value![0]))
        setMonth(value![1])
        setDayTo(defaultTo)
      }
    } else if (defaultValue) {
      const value = defaultValue.split('-')

      setYear(Number(value[0]))
      setMonth(value[1])
      setDay(value[2])
    } else {
      setDay(moment().locale('fa').format('DD'))
      setMonth(moment().locale('fa').format('MM'))
      setYear(maxYear)
    }
  }, [defaultValue, defaultFrom, defaultTo]);

  useEffect(() => {
    // setDay('')
  }, [month, year]);

  return (
    <div className='popup popupDown'>
      <div className='forMonthParent'>
        <div className='forMonth' onClick={() => dispatch(bottomDrawer(<OptionForm value={monthsRows} def={month} setOption={setMonth} />))}>{monthsRows.get(month)}</div>
        <div className='forMonth' onClick={() => dispatch(bottomDrawer(<OptionForm value={yearsRows} def={year} setOption={setYear} />))}>{yearsRows[year]}</div>
      </div>
      <div className='month monthTitle'>
        {daysRows()}
      </div>
      <div className='month day'>
        {days()}
      </div>
      <div className='parentDate'>
        <div className='share' onClick={() => done() }>{t('confirm')}</div>
        <div className='share cancelDate' onClick={() => dispatch(hideDrawer()) }>{t('cancel')}</div>
      </div>
    </div>
  )
}

export default React.memo(DatePicker)
