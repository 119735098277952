import config from 'config';
import useUser from 'hooks/useUser';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Card1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { files } = useUser();

  const send = async (file: any) => {
    dispatch(showLoading());

    const formData = new FormData();
    formData.append('type', 'passport');
    formData.append('doc', file);

    const result = await gather(`${config.api}/api/v1/account/file`, true).upload(formData);
    if (result.code === 200) {
      dispatch(successAlert(t('uploadCompleted')));
      // await dispatch(file());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='parentFixed'>
      <p>{t('uploadId')}</p>
      <div className='uploadPicture fileUploader'>
        {files.passport && (<img src={files.passport} alt='' />)}
        <FileUploader handleChange={send} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
        <span></span>
      </div>
      <div className='infoText'>
        <span></span>
        <p>{t('uploadId')}<br/>{t('uploadIdInfo')}</p>
      </div>
    </div>
  );
}

export default Card1;
