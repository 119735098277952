import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import useTimer from 'hooks/useTimer';
import useUser from 'hooks/useUser';
import { IStep } from 'interfaces/levelUp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Email = ({ setStep }: IStep) => {
  const { t } = useTranslation();
  const { profile } = useUser();
  const [isFirst, setIsFirst] = useState(true);
  const [email, setEmail] = useState(profile?.email);
  const dispatch = useDispatch();
  const timer = useTimer();
  const [code, setCode] = useState<number[]>([]);

  const sendData = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/user/email`, true).post({
      email: email,
    });

    if (result.code === 200) {
      setCode((result.data.code).toString().split(''));
      timer.start();
      setIsFirst(false);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const sendCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/user/email/code`, true).post({
      code: Object.values(code).join(''),
    });

    if (result.code === 200) {
      setStep(1);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <>
      {isFirst ? (
        <div className='loginContent verifyForm'>
          <p>{t('enterEmail')}</p>
          <input onChange={(e) => setEmail(e.target.value)} defaultValue={email} className='ltr' placeholder={'sample@email.com'} type='email' />
        </div>
      ) : (
        <div className='content loginContent'>
          <div className='parentOfInput'>
            <span>{t('confirmCodeEmailWas')} <span>{email}</span> {t('sentTo')}<i className='changeNumber' onClick={() => setIsFirst(true)}></i></span>
            <PatchCode code={code} setCode={setCode} />
          </div>
          <div className='sendAgainCode'>
            <i>{t('dontGetCodeEmail')}</i>
            {!timer.isFinish ? (
              <div>{t('sendAgainUntil')} <b>{timer.minute} : {timer.second}</b></div>
            ) : (
              <div className='active' onClick={() => sendData()}>{t('sendAgain')}</div>
            )}
          </div>
        </div>
      )}
      <div className='addExtra'></div>
      <div className='bottomVerify'>
        <div className='share' onClick={() => isFirst ? sendData() : sendCode()}>{t('addAndContinue')}</div>
      </div>
    </>
  )
}

export default Email;
