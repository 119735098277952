import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { Link } from 'react-router-dom';
import { priceColor } from 'utils/color';
import { formatter } from 'utils/numeral';

const NewCoin = () => {
  const { coins } = useGlobal();
  const { price, change } = useTicker();

  const list = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(coins).filter(e => e.isNewly).slice(0, 1);
    find.forEach((element, i) => {
      const percent = change(`${element.symbol}USDT`);
      const hue = priceColor(percent);

      rows.push(
        <Link to={`/currency/?symbol=${element.symbol}USDT`} key={i} className='newCoin'>
          <p>New in APP</p>
          <div>
            <img src={element.icon} alt='' />
            <span>{element.name}</span>
          </div>
          <span>{formatter(price(`${element.symbol}USDT`))}<i>(USDT)</i></span>
          <span className={`color${hue}`}>{formatter(percent, 2, 2)}%</span>
        </Link>
      );
    });

    return rows;
  }

  return list();
}


export default NewCoin;
