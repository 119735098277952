import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoading } from 'services/reducer/loading';
import { logoutUser } from 'utils/user';

const Logout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className='popup popupDown deleteNotif logoutNotif'>
      <div className='alertIcon'>
        <span></span>
        <p>{t('logout')}</p> 
      </div>
      <p className='alertText'>{t('logoutInfo')}</p>
      <section>
        <div className='share' onClick={() => logoutUser(true)}>{t('sure')}</div>
        <div className='share' onClick={() => dispatch(hideLoading())}>{t('cancel')}</div>
      </section>
    </div>
  )
}

export default Logout
