import Back from 'components/Icons/Back';
import useUser from 'hooks/useUser';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';

const Edit = () => {
  const { t } = useTranslation();
  const { profile } = useUser();

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup headerWallet'>
        <div className='info'>
          <Back />
          <span><h2>{t('accountInfo')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='showData'>
        <div className='loginContent verifyForm'>
          <input readOnly defaultValue={profile?.fullName} placeholder={`${t('nameAndFamily')}`} type='text' />
          <input readOnly defaultValue={profile?.mobile} className='ltr' placeholder={`${t('mobile')}`} type='mobile' />
          <input readOnly defaultValue={profile?.nationalCode} className='ltr' placeholder={`${t('nationalCode')}`} type='number' />
          <input readOnly defaultValue={profile?.birthDate ? moment(profile?.birthDate, 'YYYY-MM-DD').locale('fa').format('YYYY-MM-DD') : ''} className='ltr' placeholder={`${t('birthday')}`} type='text' />
          <input readOnly defaultValue={profile?.email} className='ltr' placeholder={`${t('email')}`} type='email' />
        </div>
        <div className='infoText'>
          <span></span>
          <p>{t('forSecurity')}</p>
        </div>
      </div>
    </div>
  );
}

export default Edit;
