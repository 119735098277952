import Login from 'components/Modules/Login';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';

const OpenLogin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const open = () => {
    dispatch(hideDrawer());

    setTimeout(() => {
      dispatch(fullDrawer(<Login />));
    }, 250);
  }

  return (
    <div className='popup popupDown'>
      <div className='alertIcon'>
        <span></span>
        <p>{t('registerLogin')}</p>
      </div>
      <p className='alertText'>{t('registerAndVerifyInfo')}</p>
      <div className='share' onClick={() => open()}>{t('registerLogin')}</div>
    </div>
  )
}

export default OpenLogin;
