import Back from 'components/Icons/Back';
import config from 'config';
import { ICheckPassword } from 'interfaces/login';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const CheckPassword = ({ setStep, token }: ICheckPassword) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const dispatch = useDispatch();

  const sendData = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/security/login/check`, true).post({
      type: 'text',
      password: password,
      token: token,
    });

    if (result.code === 200) {
      setStep(4);

      Cookies.set('accessToken', result.data.tokens.access, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('refreshToken', result.data.tokens.refresh, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('socketToken', result.data.tokens.socket, { expires: 30 * 24 * 60 * 60, path: '/' });
      
      // await getUserData(dispatch);

      dispatch(hideLoading());
      dispatch(hideDrawer());
      dispatch(successAlert(t('loginSuccess')));
      
      // reconnect(dispatch);
    } else {
      if (result?.message === '90022') {
        setIsWrong(true);
      } else {
        dispatch(errorAlert(t(result?.message) || t('errorFound')));
      }
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('loginPassword')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='showData'>
        <div className='loginContent verifyForm'>
          <div className='parentOfInput parentPassword'>
            <p>{t('enterPassword')}</p>
            <input onChange={(e) => { setPassword(e.target.value); setIsWrong(false); }} className={`${(isWrong && 'inputWrong')} ltr`} placeholder={`${t('password')}`} type={!passwordView ? 'password' : 'text'} />
            <i className={passwordView ? 'active' : ''} onClick={() => setPasswordView(!passwordView)}></i>
          </div>
          {isWrong && (
            <div className='passwordBullet'>
              <i className='wrong'>{t('wrongPassword')}</i>
            </div>
          )}
        </div>
      </div>
      <div className='share' onClick={() => sendData()}>{t('confirm')}</div>
    </div>
  );
}

export default CheckPassword;
