import { Backdrop, CircularProgress } from '@mui/material';
import Currency from 'components/Pages/Currency';
import Home from 'components/Pages/Home';
import Market from 'components/Pages/Market';
import Payment from 'components/Pages/Payment';
import Trade from 'components/Pages/Trade';
import Wallet from 'components/Pages/Wallet';
import config from 'config';
import useLoading from 'hooks/useLoading';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { changeExUnit, getCoins, getCurrencies, getLevels, getPairs, getSetting } from 'services/reducer/global';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getTickers } from 'services/reducer/ticker';
import { connect, socket } from 'services/socket';
import { checkAuth, getUserData, isUserLogin } from 'utils/user';
import AlertList from './AlertList';
import DrawerList from './DrawerList';

const App = () => {
  const dispatch = useDispatch<IDispatch>();
  const { isLoadingShow } = useLoading();
  const { i18n } = useTranslation();
  const [isReady, setIsReady] = useState(false);

  const checkLanguage = async () => {
    let lang = 'en';
    if (config.source !== 'dex') {
      lang = 'ar';
      dispatch(changeExUnit('USD'));
    }

    i18n.changeLanguage(lang);
    document.documentElement.lang = i18n.language;
  };

  const init = async () => {
    dispatch(showLoading());

    await checkAuth(true);

    await Promise.all([
      dispatch(getSetting()),
      dispatch(getCurrencies()),
      dispatch(getPairs()),
      dispatch(getCoins()),
      dispatch(getLevels()),
      dispatch(getTickers()),
    ]);

    if (isUserLogin()) {
      await getUserData(dispatch);
    }

    connect(dispatch);

    setInterval(() => {
      checkAuth();
    }, 60 * 1000);

    dispatch(hideLoading());

    socket.on('connect', () => {
      setIsReady(true);
    });
  };

  useEffect(() => {
    init();
    checkLanguage();
  }, []);

  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        <Backdrop sx={{ backgroundColor: '#000000b0', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isLoadingShow}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <AlertList />
        <DrawerList />
        {isReady && (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/market' element={<Market />} />
            <Route path='/currency' element={<Currency />} />
            <Route path='/trade' element={<Trade />} />
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/payment' element={<Payment />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;
