import config from 'config';
import { INotice } from 'interfaces/home';
import { useEffect, useState } from 'react';
import gather from 'utils/gather';

const Notice = () => {
  const [notice, setNotice] = useState([]);

  const list = () => {
    const rows: React.ReactElement[] = [];

    notice.forEach((element: INotice, i) => {
      rows.push(<a key={i} href={element.link} target='_blank' className='notice'>{element.title}</a>);
    })

    return rows;
  }

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/data/notice?limit=1`).get();
    if (result.code === 200) {
      setNotice(result.data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return list();
}

export default Notice;
