import Tabs from 'components/Helper/Tabs';
import Back from 'components/Icons/Back';
import PairList from 'components/Layouts/PairList';
import { EKind, ETab } from 'enums/priceAlert';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { leftDrawer } from 'services/reducer/drawer';
import { priceColor } from 'utils/color';
import { formatter } from 'utils/numeral';
import Data from './Data';
import Form from './Form';

const PriceAlert = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  const { price, change } = useTicker();
  const dispatch = useDispatch();
  const { pairs } = useGlobal();
  const [symbol, setSymbol] = useState(value);
  const [addNew, setAddNew] = useState(0);
  const [tab, setTab] = useState(ETab.price);
  const [kind, setKind] = useState(EKind.up);

  const tabTitle = {
    [ETab.swing]: t('swingAlert'),
    [ETab.price]: t('priceAlert'),
  }

  const tabKind = {
    [EKind.up]: t('upperThan'),
    [EKind.down]: t('lowerThan'),
    [EKind.equal]: t('equal'),
  }
  
  const element = pairs[symbol];
  const percent = formatter(change(symbol, 0), 2, 0);
  const hue = priceColor(Number(percent));

  const kindList = () => {
    const rows: React.ReactElement[] = [];

    Object.entries(tabKind).forEach(([key, value], i) => {
      rows.push(<span className={kind === key ? 'active' : ''} onClick={() => setKind(key as EKind)} key={i}><i></i>{value}</span>);
    });

    return rows;
  }

  return (
    <div className={`popup popup_${tab}`}>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('priceAlert')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='currencyTitle selfieTitle priceAlertTitle'>
        <Tabs titles={tabTitle} setSelected={setTab} selected={tab} />
      </div>
      <div className='headDetail alertDetail'>
        <h2 onClick={() => dispatch(leftDrawer(<PairList setSelect={setSymbol} />))}>{element.name}</h2>
        <h6 className={`color${hue}`}>{formatter(price(symbol, 1))}</h6>
        <p className={`color${hue}`}>{percent}%</p>
      </div>
      <div className='kindOfAlert'>
        {kindList()}
      </div>
      <Form symbol={symbol} tab={tab} kind={kind} setAddNew={setAddNew} />
      <h6 className='alertsTitle'>{t('alerts')}</h6>
      <Data tabKind={tabKind} addNew={addNew} />
    </div>
  )
}

export default PriceAlert;
