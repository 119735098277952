import { ELevel } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import Form from './one/Form';

const Level1 = () => {
  const { t } = useTranslation();
  const [isDone, setIsDone] = useState(false);
  const { levels } = useGlobal();
  const dispatch = useDispatch();

  const data = Object.values(levels).find((e) => e.type === ELevel.l1);

  const list = () => {
    const rows: React.ReactElement[] = [];

    data?.description.forEach((value, key) => {
      rows.push(<span key={key}><strong style={{ backgroundColor: `#${data?.color}` }}></strong><i>{value}</i></span>);
    });

    return rows;
  }

  return (
    <div className={`${(isDone && 'doneData')} popup popupFix`}>
      {isDone ? (
        <>
          <div className='fullBox'>
            <div className='doneNormal' style={{ backgroundColor: `#${data?.color}` }}></div>
            <h3>{t('verifyLevel')} {data?.title}</h3>
            <p>{t('yourDataSavedInfo')}</p>
            <div className='infoText'>
              <span></span>
              <i>{t('levelNews')}:</i>
            </div>
            <div className='newLevelData'>
              {list()}
            </div>
          </div>
          <div className='bottomVerify'>
            <div className='share' onClick={() => dispatch(hideDrawer())}>{t('iAmUnderstand')}</div>
          </div>
        </>
      ) : (
        <Form setIsDone={setIsDone} />
      )}
    </div>
  );
}

export default Level1;
