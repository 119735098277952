import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const Convert = ({ value }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <span><i>{t('amount')}</i><b>{autoFormatter(value.amount)} {value.symbol}</b></span>
      <span><i>{t('from')}</i><b>{value.from}</b></span>
      <span><i>{t('to')}</i><b>{value.to}</b></span>
    </>
  );
}

export default Convert;
