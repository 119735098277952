import config from 'config';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { bottomDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import Rate from './Rate';

const Close = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const close = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/support`, true).delete();
    if (result.code === 200) {
      dispatch(hideLoading());
      dispatch(hideDrawer());
      dispatch(hideDrawer());

      setTimeout(() => {
        dispatch(bottomDrawer(<Rate />));
      }, 300);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  return (
    <div className='popup popupDown'>
      <div className='confirmTitle'>{t('closeChat')}</div>
      <div className='parentDate'>
        <div className='share' onClick={() => close()}>{t('endChat')}</div>
        <div className='share backChat' onClick={() => dispatch(hideDrawer())}>{t('back')}</div>
      </div>
    </div>
  );
}

export default Close;
