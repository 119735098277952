import Alarm from 'components/Icons/Alarm';
import Profile from 'components/Icons/Profile';
import Support from 'components/Icons/Support';
import Menu from 'components/Layouts/Menu';
import Shortcuts from 'components/Layouts/Shortcuts';
import config from 'config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { refreshPage } from 'utils/tools';
import FastBuy from './FastBuy';
import Language from './Language';
import MainList from './MainList';
import NewCoin from './NewCoin';
import Notice from './Notice';
import Slider from './Slider';
import TopList from './TopList';
import TopSearch from './TopSearch';

const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Notice />
      <Menu />
      <PullToRefresh onRefresh={() => refreshPage()}>
        <>
          <div className='main'>
            <header className='header'>
              {config.source === 'corypton' && <Language />}
              <Alarm />
              <Support />
              <a href={`${config.site}/blog`} target='_blank' className='headIcon news'></a>
              <TopSearch />
              <Profile />
            </header>
          </div>
          <div className='lineSep'></div>
          <div className='main'>
            <section className='fast'>
              <FastBuy />
              <Slider />
            </section>
            <Shortcuts />
            <TopList />
            <MainList />
            <NewCoin />
            <a href={`${config.site}/blog`} rel='noreferrer' target='_blank' className='blog'>
              <div>
                <div>
                  <i>{t('knowMore')}</i>
                  <p>Crypto/Trade/To Earn</p>
                </div>
              </div>
              <span></span>
            </a>
          </div>
          <div className='extraBottom'></div>
        </>
      </PullToRefresh>
    </>
  );
}

export default Home;
