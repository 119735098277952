import config from 'config';
import { EType } from 'enums/chat';
import useGlobal from 'hooks/useGlobal';
import { INew } from 'interfaces/chat';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const New = ({ setIsSystem, setIsNewChat, setChatList, setSystemList }: INew) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subjects } = useGlobal();

  const systemRoom = async (type: string) => {
    const find = subjects.find(e => e.slug === type);

    setChatList([{
      isOperator: false,
      type: EType.text,
      value: find?.title!,
    }, {
      isOperator: true,
      type: EType.text,
      value: find?.title!,
    }]);
  }

  const newRoom = async (type: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/support`, true).post({
      subject: type,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setIsSystem(true);
      setIsNewChat(false);
      setSystemList(result.data);
      systemRoom(type);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  const list = () => {
    const rows: React.ReactElement[] = [];
    
    subjects.forEach((e, i) => {
      rows.push(<span key={i} onClick={() => newRoom(e.slug)}>{e.title}</span>);
    });

    return rows;
  }

  return (
    <>
      <p className='newChatText'>{t('welcomeToChat')}</p>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='popup popupDown optionList supportList'>
          <div className='selectSubject'>{t('selectSubject')}</div>
          {list()}
          <div className='backSupport' onClick={() => dispatch(hideDrawer()) }>{t('back')}</div>
        </div>
      </div>
    </>
  );
}

export default New;
