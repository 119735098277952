import config from 'config';
import { ICode } from 'interfaces/login';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';
import CheckPassword from './CheckPassword';

let MAX = 0
let INTER: NodeJS.Timeout;

const Code = ({ mobile, setStep, setCode, code, setToken, auth }: ICode) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState([0, 0]);

  const setCodeData = (e: any) => {
    const value = e.target.value.slice(0, 1);
    
    setCode((prevCode) => {
      const newValue = [...prevCode];
      newValue[e] = value;
      return newValue;
    });

    const form = e.target.form;
    const index = [...form].indexOf(e.target);
    if (index < 5 && value !== '' && e.target.name !== 5) {
      form.elements[index + 1].focus();
      form.elements[index + 1].select();
    }
  }

  const selectText = (e: any) => {
    e.target.select();
  }

  const checkCode = (e:any) => {
    if (!isNumber(e.key)) {
      e.target.value = '';
    }
  }

  const runCounter = () => {
    let minutes = Math.floor(MAX / 60);
    let seconds = Math.floor(MAX % 60);

    setTimer([minutes, seconds]);

    MAX--;
    if (MAX < 0) {
      clearInterval(INTER);
    }
  }

  const startTimer = () => {
    if (INTER) {
      clearInterval(INTER);
    }

    MAX = 10;

    runCounter();
    INTER = setInterval(() => {
      runCounter();
    }, 1000);
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    for (let i = 0; i <= 5; i++) {
      rows.push(<input key={i} value={code[i]} name={i.toString()} onKeyUp={(e) => checkCode(e)} onChange={(e) => setCodeData(e)} onClick={(e) => selectText(e)} type='number' />);
    }
    
    return rows;
  }
  
  const login = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/security/login/code`).post({
      mobile: mobile.startsWith('0') ? mobile.substring(1) : mobile,
      prefix: '+98',
      code: code.join(''),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setToken(result.data.token);
      if (!result.data.hasPassword) {
        setStep(3);
      } else {
        dispatch(fullDrawer(<CheckPassword token={result.data.token} setStep={setStep} />))
      }
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(INTER);
    }
  }, []);

  return (
    <>
      <div className='logoNew'></div>
      <h2>{t('confrimMobile')}</h2>
      <div className='parentOfInput'>
        <p>{t('confirmCodeWas')} <b>{numbersToEn(mobile)}</b> {t('sentTo')}<i className='changeNumber' onClick={() => setStep(1)}></i></p>
        <form className='verifyCode'>
          {list()}
        </form>
      </div>
      <div className='share' onClick={() => login()}>{t('submit')}</div>
      <div className='sendAgainCode'>
        <i>{t('dontGetCode')}</i>
        {MAX >= 0 ? (
          <div>{t('sendAgainUntil')} <b>{('0' + timer[0]).slice(-2)} : {('0' + timer[1]).slice(-2)}</b></div>
        ) : (
          <div className='active' onClick={() => { startTimer(); auth(); }}>{t('sendAgain')}</div>
        )}
      </div>
    </>
  )
}

export default Code;
