import config from 'config';
import { ISend } from 'interfaces/convert';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Send = ({ dataTo, dataFrom, amount }: ISend) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const start = async () => {
    if (!dataTo) {
      dispatch(errorAlert(t('selectToSymbol')));
      return
    }

    dispatch(showLoading());

    const result = await gather(config.api + '/api/v1/capital/convert', true).post({
      from: dataFrom.symbol,
      amount: amount,
      to: dataTo.symbol,
    });

    if (result.code === 200) {
      dispatch(successAlert(t('submitSuccessful')));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='showData'>
      <div className='dataShare'></div>
      <div className='share' onClick={() => start()}>{t('exchange')}</div>
    </div>
  )
}

export default Send;
