import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const DepositWithdraw = ({ value }: any) => {
  const { t } = useTranslation();
  
  return (
    <>
      <span><i>{t('amount')}</i><b>{autoFormatter(value.amount)} {value.symbol}</b></span>
      {value.fee && (
        <span>
          <i>{t('fee')}</i>
          <b>{autoFormatter(value.fee)} {value.symbol}</b>
        </span>
      )}
      <span><i>{value.type === 'withdraw' ? t('amountReceived') : t('amountSent')}</i><b>{autoFormatter(value.receive)} {value.symbol}</b></span>
      {value.type === 'charge' && <span><i>{t('payWay')}</i><b>{t('online')}</b></span>}
      <span><i>{t('trx')}</i><b>{value.transaction}</b></span>
    </>
  );
}

export default DepositWithdraw;
