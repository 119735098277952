import Percentage from 'components/Helper/Percentage';
import CoinList from 'components/Layouts/CoinList';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IForm } from 'interfaces/convert';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { leftDrawer } from 'services/reducer/drawer';
import { formatter, getPercent } from 'utils/numeral';

const Form = ({ dataTo, dataFrom, setFrom, setTo, amount, setAmount }: IForm) => {
  const { t } = useTranslation();
  const { price } = useTicker();
  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const { balance } = useUser();
  
  const wallet = balance.wallet[dataFrom?.symbol];
  const fromPrice = price(`${dataFrom?.symbol}USDT`, 1);
  const toPrice = price(`${dataTo?.symbol}USDT`, 1);
  const rate = fromPrice / toPrice;
  const receive = dataTo ? (amount * rate) : 0;
  
  const changePlace = () => {
    setFrom(dataTo.symbol);
    setTo(dataFrom.symbol);
  }

  useEffect(() => {
    if (getPercent(wallet?.free, percentage) != amount) {
      setPercentage(0);
    }
  }, [amount]);

  useEffect(() => {
    percentage > 0 && setAmount(getPercent(wallet?.free, percentage));
  }, [percentage]);

  useEffect(() => {
    setAmount(0);
    setPercentage(0);
  }, [dataFrom]);

  return (
    <>
      <div className='twoBox twoBoxCenter'>
        <div className='box boxMarket boxReceive dropIcon' onClick={() => dispatch(leftDrawer(<CoinList setSelect={setFrom} />))}>
          <img src={dataFrom.icon} alt='' />
          {dataFrom.symbol}
        </div>
        <div className='linePay'></div>
        <div className='box boxMarket boxSingle'>
          <NumericFormat value={amount > 0 ? amount : ''} allowNegative={false} thousandSeparator={true} onValueChange={(e) => setAmount(Number(e.value))} placeholder='0.00' />
        </div>
      </div>
      <Percentage percentage={percentage} setPercentage={setPercentage} />
      <div className='changeArrow' onClick={() => changePlace()}></div>
      <div className='twoBox twoBoxCenter'>
        <div className='box boxMarket boxReceive dropIcon' onClick={() => { dispatch(leftDrawer(<CoinList setSelect={setTo} />)) }}>
          {!dataTo ? (
            t('selectCrypto')
          ) : (
            <>
              <img src={dataTo.icon} alt='' />
              {dataTo.symbol}
            </>
          )}
        </div>
        <div className='linePay'></div>
        <div className='box boxMarket boxSingle'>
          <input placeholder='0.00' disabled value={receive > 0 ? formatter(receive) : ''} />
        </div>
      </div>
      {dataTo && (
        <div className='infoData'>
          <span><p>{t('exchangeRate')}</p><i>1 {dataFrom.symbol.toUpperCase()} = {formatter(rate)} {dataTo.symbol}</i></span>
          <span><p>{t('receiveAmount')}</p><i>{formatter(receive)} {dataTo.symbol}</i></span>
        </div>
      )}
    </>
  )
}

export default Form;
