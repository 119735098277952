import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { IBalance, ICard, IProfile, IResponse } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  balance: {
    wallet: {} as { [k: string]: IBalance },
    account: {} as { [k: string]: IBalance },
  },
  profile: {} as IProfile,
  cards: {} as { [k: string]: ICard },
  files: {} as { [k: string]: string },
}

const getCards = createAsyncThunk('list/fetchList', async () => {
  return await gather(`${config.api}/api/v1/account/card`, true).get();
});

const addCard = createAsyncThunk('add/fetchAdd', async (data: any): Promise<IResponse> => {
  return await gather(`${config.api}/api/v1/account/card`, true).post(data);
});

const getAccountBalance = createAsyncThunk('accountBalance/fetchAccountBalance', async () => {
  return await gather(`${config.api}/api/v1/account/balance`, true).get();
});

const getFiles = createAsyncThunk('file/fetchFile', async () => {
  return await gather(`${config.api}/api/v1/account/file`, true).get();
});

const getProfile = createAsyncThunk('profile/fetchprofile', async () => {
  return await gather(`${config.api}/api/v1/account/user`, true).get();
});

const getWalletBalance = createAsyncThunk('walletBalance/fetchWalletBalance', async () => {
  return await gather(`${config.api}/api/v1/capital/asset`, true).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateBalance: (state, action) => {
      state.balance.account[action.payload.symbol] = action.payload;
    },
    updateAsset: (state, action) => {
      state.balance.wallet[action.payload.symbol] = action.payload;
    },
    updateLevel: (state, action) => {
      state.profile.level = action.payload;
    },
    updateProfile: (state, action) => {
      if (action.payload.nationalCode) {
        state.profile.nationalCode = action.payload.nationalCode;
      }
      if (action.payload.birthDate) {
        state.profile.birthDate = action.payload.birthDate;
      }
    },
    removeCard: (state, actions) => {
      delete state.cards[actions.payload];

      gather(`${config.api}/api/v1/account/card`, true).delete({
        card: actions.payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCards.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ICard) => {
            state.cards[value.number] = value;
            state.cards[value.number].icon = value?.bank && `${config.api}/banks/${value.bank.toLowerCase()}.png`;
          });
        }
      })
      .addCase(addCard.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.cards[action.meta.arg.number] = action.meta.arg
        }
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.balance.wallet[value.symbol] = value;
          })
        }
      })
      .addCase(getAccountBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.balance.account[value.symbol] = value;
          })
        }
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.profile = action.payload.data;
        }
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.files[value.type] = value.link;
          })
        }
      })
  },
});

export {
  addCard,
  getAccountBalance,
  getCards,
  getFiles,
  getProfile,
  getWalletBalance
};

export const {
  updateAsset,
  updateBalance,
  updateLevel,
  updateProfile,
} = slice.actions;

export default slice.reducer;
