import Back from 'components/Icons/Back';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';

const Levels = () => {
  const { t } = useTranslation();
  const { profile } = useUser();
  const { levels } = useGlobal();
  
  const list = () => {
    const rows: React.ReactElement[] = [];

    Object.values(levels).forEach((value, i) => {
      rows.push(
        <div key={i}>
          <span style={{ background: value.color }}>{value.title}</span>
          <section>
            <div>
              <i>{t('condition')}</i>
              <p>{value.condition.map((s: string) => <>{s}<br/></>)}</p>
            </div>
            <div>
              <i>{t('possibilities')}</i>
              <p>{value.description.map((s: string) => <>{s}<br/></>)}</p>
            </div>
          </section>
        </div>
      )
    })

    return rows;
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('levelInfo')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='level'>
        <p>{t('currentLevel')}</p>
        <p>{levels[profile.level]?.title}</p>
      </div>
      <div className='pack'>
        {list()}
      </div>
    </div>
  )
}

export default Levels;
