import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from 'utils/gather';

const initialState = {
  favorites: [] as string[],
}

const getFavorites = createAsyncThunk('favorites/fetchfavorites', async () => {
  return await gather(`${config.api}/api/v1/account/favorite`, true).get();
});

const slice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addFavorite: (state, actions) => {
      state.favorites.push(actions.payload);

      gather(`${config.api}/api/v1/account/favorite`, true).post({
        pair: actions.payload,
      });
    },
    removeFavorite: (state, actions) => {
      const index = state.favorites.indexOf(actions.payload);
      if (index > -1) {
        state.favorites.splice(index, 1);
      }

      gather(`${config.api}/api/v1/account/favorite`, true).delete({
        pair: actions.payload,
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.favorites = action.payload.data;
        }
      })
  }
});

export {
  getFavorites
};

export const { 
  addFavorite, 
  removeFavorite,
} = slice.actions;

export default slice.reducer;
