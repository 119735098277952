import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Form = ({ symbol, tab, kind, setAddNew }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const send = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/alarm`, true).post({
      pair: symbol,
      type: tab,
      value: value,
      kind: kind,
    });

    if (result.code === 200) {
      setAddNew(Math.random())
      setValue(0);
      dispatch(successAlert(t('successful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='loginContent verifyForm alertForm'>
      <NumericFormat value={value > 0 ? value : ''} thousandSeparator={true} onValueChange={(e) => setValue(Number(e.value)) } placeholder={(tab === 'price' ? t('price') : t('fivePercent'))} />
      <div className='share' onClick={() => send()}>{t('submit')}</div>
    </div>
  )
}

export default Form;
