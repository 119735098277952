import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';

const Close = () => {
  const dispatch = useDispatch();
  
  return (
    <div className='closeParentTrans'>
      <div className='closeTrans' onClick={() => dispatch(hideDrawer())}></div>
    </div>
  )
}

export default Close;
