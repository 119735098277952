import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';

const Back = () => {
  const dispatch = useDispatch();
  
  return (<div className='back' onClick={() => dispatch(hideDrawer())}></div>);
}

export default Back;
