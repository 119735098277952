import SearchBox from 'components/Layouts/SearchBox';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatter } from 'utils/numeral';

const TopSearch = () => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const { pairs, coins } = useGlobal();
  const myRef = useRef<HTMLDivElement>(null);
  const { price } = useTicker();

  const list = () => {
    const rows: React.ReactElement[] = [];
    
    const find = Object.values(pairs).filter(e => e.name.toLowerCase().includes(search.toLowerCase()) || e.base.toLowerCase().includes(search.toLowerCase()));
    find.forEach((element, i) => {
      const coin = coins[element.base];
      
      rows.push(
        <Link to={`/currency/?symbol=${element.symbol}`} key={i}>
          <img src={coin.icon} />
          <i>{element.base}<b> / {element.quote}</b></i>${formatter(price(element.symbol))}
        </Link>
      )
    })

    if (rows.length <= 0) {
      rows.push(<div className='notFound' key={1}>{t('notFound')}</div>);
    }

    return rows;
  }

  const handleClickOutside = (event: any) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setSearch('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='topOfSearch'>
      <SearchBox setSearch={setSearch} />
      {search && (
        <div className='searchBox' ref={myRef} onClick={e => e.stopPropagation()}>{list()}</div>
      )}
    </div>
  )
}

export default TopSearch;
