import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { priceColor } from 'utils/color';
import { autoFormatter, formatter } from 'utils/numeral';

const TopList = () => {
  const { coins, currencyUnit } = useGlobal();
  const { price, change } = useTicker();

  const list = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(coins).filter(e => e.symbol === 'BTC' || e.symbol === 'ETH');
    find.forEach((element, i) => {
      const percent = change(`${element.symbol}USDT`);
      const hue = priceColor(percent);

      rows.push(
        <div key={i}>
          <div className='topUp'>
            <img src={`/images/${element.symbol.toLowerCase()}.svg`} alt='' />
            <i>{element.symbol}</i>
            <p className={`color${hue}`}>{formatter(percent, 2, 2)}%</p>
          </div>
          <div className='topMain'>
            <i>${autoFormatter(price(`${element.symbol}USDT`, 1))}</i>
          </div>
          <div className='topUsd'>{autoFormatter(price(`${element.symbol}${currencyUnit}`, 1))} <i>{currencyUnit}</i></div>
        </div>
      );
    })

    return rows;
  }

  return (
    <section className='top'>
      {list()}
    </section>
  )
}

export default TopList;
