import Percentage from 'components/Helper/Percentage';
import config from 'config';
import { EMode, ETransaction } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IFormProps } from 'interfaces/express';
import { IOrderList } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { socket } from 'services/socket';
import gather from 'utils/gather';
import { formatter, getPercent } from 'utils/numeral';

const Form = ({ data, type }: IFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { balance } = useUser();
  const { price } = useTicker();
  const [percentage, setPercentage] = useState(0);
  const [orderList, setOrderList] = useState<IOrderList>({
    buy: [],
    sell: [],
  });
  const [amount, setAmount] = useState(0);
  const { currencyUnit } = useGlobal();

  const list = orderList[type !== ETransaction.buy ? ETransaction.buy : ETransaction.sell];
  const max = list.reduce((n, { amount }) => n + amount, 0);
  console.log('max', max);
  console.log(type, list);

  let sum = 0;
  let remain = 0;
  list.forEach(e => {
    if (remain <= amount) {
      sum += e.price * e.amount;
      remain += e.amount;
    }
  });

  const priceFind = sum / remain || 0;
  console.log('remain', remain);
  console.log('sum', sum);
  // console.log('orderList', orderList);

  const userBalance = type === ETransaction.buy ? 
    (balance.account[currencyUnit])?.free! / price(data.symbol+currencyUnit) : 
    (balance.wallet[data.symbol])?.free!;
  
  const receiveAmount = ((type === ETransaction.sell) ? amount * price(data.symbol+currencyUnit) : amount) || 0;

  const start = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/capital/order`, true).post({
      pair: data.symbol+currencyUnit,
      price: priceFind,
      amount: amount,
      mode: EMode.limit,
      type: type,
    });

    if (result.code === 200) {
      hideDrawer();
      dispatch(successAlert(t('orderSuccessful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const orderBook = (result: any) => {
    setOrderList(result);
  }

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/capital/order/book/${data.symbol+currencyUnit}`).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }
  }

  useEffect(() => {
    if (getPercent(userBalance, percentage) != amount) {
      setPercentage(0);
    }
  }, [amount]);

  useEffect(() => {
    percentage > 0 && setAmount(getPercent(userBalance, percentage));
  }, [percentage]);

  useEffect(() => {
    setAmount(0);
    setPercentage(0);
  }, [data, type]);

  useEffect(() => {
    getData();

    socket.emit('subscribe_orderBook', data.symbol+currencyUnit);
    socket.on('orderBook', orderBook);

    return () => {
      socket.emit('unsubscribe_orderBook', data.symbol+currencyUnit);
      socket.removeListener('orderBook', orderBook);
    }
  }, [data]);
  
  return (
    <>
      <div className='box boxMarket boxSingle'>
        <p>{t('enterCryptoAmount')}</p>
        <NumericFormat value={amount > 0 ? amount : ''} max={max} thousandSeparator={true} onValueChange={(e) => setAmount(Number(e.value))} placeholder='0.00' />
      </div>
      <Percentage percentage={percentage} setPercentage={setPercentage} />
      <div className='infoData'>
        <span><p>{t('max')}</p><i>{formatter(max, 6)} {(data.symbol)}</i></span>
        <span><p>{t('price')}</p><i>{formatter(priceFind, 6)} {(currencyUnit)}</i></span>
        <span><p>{t('receiveAmount')}</p><i>{formatter(receiveAmount, 6)} {(type === ETransaction.buy ? data.symbol : currencyUnit)}</i></span>
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => start()}>{type === ETransaction.sell ? t('sell') : t('buy')}</div>
      </div>
    </>
  )
}

export default Form;
