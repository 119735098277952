import config from 'config';
import { ISystem } from 'interfaces/chat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const System = ({ systemList, setSystemList, setIsNewChat }: ISystem) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [backData, setBackData] = useState<string[]>([]);

  const findQuestion = async (value: string, isBack = false) => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/support/system`, true).post({
      subject: value,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setSystemList(result.data);

      let oldData = backData;
      if (isBack) {
        oldData = oldData.slice(0, -2);
      }
      setBackData([...oldData, value]);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }
  
  const backSystem = () => {
    if (backData.length > 1) {
      findQuestion(backData[backData.length - 2], true);
    } else {
      setBackData([]);
      setIsNewChat(true);
    }
  }

  const systemData = () => {
    const rows: React.ReactElement[] = [];

    if (systemList.length > 0) {
      systemList.forEach((value, i) => {
        rows.push(
          <span key={i} onClick={() => { Object.keys(systemList).length > 1 && findQuestion(value.slug); }}>{value.title}</span>
        )
      });
    }

    return rows;
  }

  return (
    <div className={'systemData systemData_' + Object.keys(systemList).length + ''}>
      {systemData()}
      <span onClick={() => backSystem() }>{t('back')}</span>
    </div>
  )
}

export default System;
