import OptionForm from 'components/Helper/OptionForm';
import Percentage from 'components/Helper/Percentage';
import Tabs from 'components/Helper/Tabs';
import NoBalance from 'components/Layouts/NoBalance';
import OpenLogin from 'components/Layouts/OpenLogin';
import config from 'config';
import { EMode, EPairModel, ETransaction } from 'enums/global';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IDirectProps } from 'interfaces/trade';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter, getPercent, toFixed } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const Direct = ({ symbol, data }: IDirectProps) => {
  const { t } = useTranslation();
  const { balance } = useUser();
  const { price } = useTicker();
  const dispatch = useDispatch();
  const location = useLocation();
  const [mode, setMode] = useState(EMode.market);
  const [type, setType] = useState((location?.state?.type as ETransaction) || ETransaction.buy);
  const [limit, setLimit] = useState(0);
  const [amount, setAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const typeTitle = {
    [ETransaction.sell]: t('sell'),
    [ETransaction.buy]: t('buy'),
  };

  const modeTitle = {
    [EMode.limit]: t('limitShowPrice'),
    [EMode.market]: t('marketShowPrice'),
  };

  const selectPrice = (mode === EMode.limit) ? limit : price(symbol);
  const receiveAmount = ((type === 'sell') ? amount * selectPrice : amount) || 0;

  const userBalance = data.model === EPairModel.currency && type === ETransaction.buy ? 
    (balance.account[data.quote])?.free! / selectPrice : 
    (balance.wallet[type === ETransaction.buy ? data.quote : data.base])?.free!;
  
  const send = async () => {
    if (receiveAmount <= 0 || selectPrice <= 0) {
      dispatch(errorAlert(t('noPriceAndValue')));
      return;
    }

    if (amount > userBalance) {
      dispatch(bottomDrawer(<NoBalance />));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/capital/order`, true).post({
      pair: symbol,
      price: selectPrice,
      amount: toFixed(type === 'sell' ? amount : receiveAmount, 6),
      mode: mode,
      type: type,
    });

    if (result.code === 200) {
      setLimit(0);
      setAmount(0);
      setPercentage(0);

      dispatch(successAlert(t('orderSuccessful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    if (getPercent(userBalance, percentage) != amount) {
      setPercentage(0);
    }
  }, [amount]);

  useEffect(() => {
    percentage > 0 && setAmount(getPercent(userBalance, percentage));
  }, [percentage]);

  useEffect(() => {
    setLimit(0);
    setAmount(0);
    setPercentage(0);
  }, [data, type]);

  return (
    <>
      <div className='mainTab'>
        <Tabs titles={typeTitle} selected={type} setSelected={setType} />
      </div>
      <div className='secondTabNew' onClick={() => dispatch(bottomDrawer(<OptionForm value={modeTitle} def={mode} setOption={setMode} />))}>
        <span>{t(`${mode}ShowPrice`)}</span>
        <i></i>
      </div>
      {mode === EMode.limit ? (
        <div className='box'>
          <span onClick={() => setLimit(limit + 1000)}></span>
          <NumericFormat
            value={limit > 0 ? limit : ''}
            allowNegative={false}
            thousandSeparator={true}
            onValueChange={(e) => setLimit(Number(e.value))}
            placeholder={`${data.quote} ${t('priceTo')}`}
          />
          <span onClick={() => setLimit(limit - 1000)}></span>
        </div>
      ) : (
        <div className='box boxMarket'>{t('marketPrice')}</div>
      )}
      <div className='box'>
        <span onClick={() => setAmount(amount + 1000)}></span>
        <NumericFormat
          value={amount > 0 ? amount : ''}
          allowNegative={false}
          thousandSeparator={true}
          onValueChange={(e) => setAmount(Number(e.value))}
          placeholder={`${(data.base)} ${t('amountTo')}`}
        />
        <span onClick={() => setAmount(amount - 1000)}></span>
      </div>
      <Percentage percentage={percentage} setPercentage={setPercentage} />
      <div className='almost'>≈ {formatter(receiveAmount, 6)} {(type === ETransaction.sell ? data.quote : data.base)}</div>
      <div className={`buyAndSell buyAndSell_${type}`}>
        <span onClick={() => !isUserLogin() ? dispatch(bottomDrawer(<OpenLogin />)) : send()}>{type === ETransaction.sell ? t('sell') : t('buy')}</span>
      </div>
    </>
  );
};

export default Direct;
