import { Alert, Collapse } from '@mui/material';
import useAlert from 'hooks/useAlert';
import { IAlert } from 'interfaces/reducer';
import { useDispatch } from 'react-redux';
import { hideAlert, removeAlert, showAlert } from 'services/reducer/alert';

const timerHide: NodeJS.Timeout[] = [];
const timerShow: NodeJS.Timeout[] = [];

const AlertList = () => {
  const dispatch = useDispatch();
  const { alerts } = useAlert();

  const handleClose = async (id: number) => {
    clearTimeout(timerHide[id]);
    clearTimeout(timerShow[id]);

    dispatch(hideAlert(id));
    setTimeout(() => {
      dispatch(removeAlert(id));
    }, 200);
  }

  const setTimerHide = (id: number) => {
    if (!timerHide[id]) {
      timerHide[id] = setTimeout(() => {
        handleClose(id);
      }, 4000);
    }
  }

  const setTimerShow = (id: number) => {
    if (!timerShow[id]) {
      timerShow[id] = setTimeout(() => {
        dispatch(showAlert(id));
      }, 50);
    }
  }

  const list = () => {
    const rows: React.ReactElement[] = [];
    
    alerts.forEach((value: IAlert) => {
      setTimerHide(value.id);
      setTimerShow(value.id);
      
      rows.push(
        <Collapse key={value.id} in={value.open}>
          <Alert onClose={() => handleClose(value.id)} severity={value.severity} sx={{ width: '100%', mb: '10px' }}>
            {value.text}
          </Alert>
        </Collapse>
      );
    });

    return rows;
  }

  return (<div className='alertParent'>{list()}</div>);
}

export default AlertList;
