import useGlobal from 'hooks/useGlobal';
import { ICoinList } from 'interfaces/layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import SearchBox from './SearchBox';

const CoinList = ({ setSelect }: ICoinList) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { coins } = useGlobal();

  const open = (value: string) => {
    dispatch(hideDrawer());
    setSelect(value);
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(coins).filter(e => e.name.toLowerCase().includes(search.toLowerCase()) || e.symbol.toLowerCase().includes(search.toLowerCase()));
    if (find.length > 0) {
      find.forEach((element, i) => {
        rows.push(
          <div key={i} className='currencyList' onClick={() => {open(element.symbol)}}>
            <span></span>
            <span><img src={element.icon} alt='' /> {element.name} ({element.symbol})</span>
          </div>
        );
      });
    } else {
      rows.push(<div className='notFound' key={1}>{t('notFound')}</div>);
    }

    return rows;
  }

  return (
    <div className='popup popupCurrency'>
      <div className='header headerPopup'>
        <SearchBox setSearch={setSearch} />
      </div>
      <div className={`notifList transList`}>
        {list()}
      </div>
    </div>
  );
}

export default CoinList;
