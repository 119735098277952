import OpenLogin from 'components/Layouts/OpenLogin';
import useFavorite from 'hooks/useFavorite';
import { IFavoriteProps } from 'interfaces/currency';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { addFavorite, removeFavorite } from 'services/reducer/favorite';
import { isUserLogin } from 'utils/user';

const Favorite = ({ symbol }: IFavoriteProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { favorites } = useFavorite();

  const add = async () => {
    if (!isUserLogin()) {
      dispatch(bottomDrawer(<OpenLogin />));
      return;
    }

    if (favorites.includes(symbol)) {
      dispatch(removeFavorite(symbol));
      dispatch(successAlert(t('deleteFav')));
    } else {
      dispatch(addFavorite(symbol));
      dispatch(successAlert(t('addFav')));
    }
  }
  
  return (<div onClick={() => add()} className={`headIcon headStar ${favorites.includes(symbol) && 'active'}`}></div>);
}

export default Favorite;
