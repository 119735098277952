import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const SendReceive = ({ value }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <b>{value.coin}</b>
      <b><i>{value.network}</i></b>
      <b><i>{t('amount')}</i> {autoFormatter(value.amount)}</b>
    </div>
  )
}

export default SendReceive;
