import Alarm from 'components/Icons/Alarm';
import Support from 'components/Icons/Support';
import Menu from 'components/Layouts/Menu';
import SearchBox from 'components/Layouts/SearchBox';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { fullDrawer } from 'services/reducer/drawer';
import { refreshPage } from 'utils/tools';
import Heatmap from './Heatmap';
import MainList from './MainList';

const Market = () => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menu />
      <PullToRefresh onRefresh={() => refreshPage()}>
        <>
          <div className='main'>
            <header className='header headerMarket'>
              <Alarm />
              <Support />
              <div className='headIcon map' onClick={() => dispatch(fullDrawer(<Heatmap />))}></div>
              <SearchBox setSearch={setSearch} />
            </header>
          </div>
          <MainList search={search} />
          <div className='extraBottom'></div>
        </>
      </PullToRefresh>
    </>
  );
}

export default Market;
