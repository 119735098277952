import { CircularProgress } from '@mui/material';
import Back from 'components/Icons/Back';
import config from 'config';
import moment from 'jalali-moment';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';
import Delete from './Delete';

const Notif = () => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [finish, setFinish] = useState(false);
  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);

    const result = await gather(`${config.api}/api/v1/live/notif?page=${page}`, true).get();

    setLoading(false);

    if (result.code === 200) {
      if (result.data.length < 50) {
        setFinish(true);
      }

      setList(list.concat(result.data));
      setPage(page + 1);
    }
  }

  const showData = () => {
    const rows: React.ReactElement[] = [];

    if (list.length > 0) {
      list.forEach((value: any, i) => {
        rows.push(
          <span className='notifData' key={i}>
            <i>{moment(value.createdAt).locale('fa').format('MM MMM , HH:mm')}</i>
            {t(value.message)}
          </span>
        );
      });
    } else {
      rows.push(<span className='notifData notFound' key={1}>{t('notFound')}</span>);
    }
    
    return rows;
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='popup'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('alarmsList')}</h2></span>
          <div className={`notifIcon ${isEmpty(list) && 'deactive'}`} onClick={() => { !isEmpty(list) && dispatch(bottomDrawer(<Delete setNotifList={setList} />)) }}></div>
        </div>
      </div>
      <div className='notifList'>
        {showData()}
        {(!loading && !finish) && (
          <span className='showMore' onClick={() => getData()} key={100000}>{t('showMore')} »</span>
        )}
      </div>
      {loading && (
        <div className='loadingParent'>
          <CircularProgress color='info' size={30} />
        </div>
      )}
    </div>
  )
}

export default Notif;
