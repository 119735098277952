import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const Info = () => {
  const { t } = useTranslation();
  const { price } = useTicker();
  const { currencyUnit } = useGlobal();
  const { balance } = useUser();
  
  let sumFree = 0;
  let sumAmount = 0;

  Object.values(balance.wallet).forEach(e => {
    if (e.free > 0) {
      sumFree += price(`${e.symbol}USDT`, 1) * e.free;
    }
    if (e.amount > 0) {
      sumAmount += price(`${e.symbol}USDT`, 1) * e.amount;
    }
  })

  Object.values(balance.account).forEach(e => {
    if (e.free > 0) {
      sumFree += e.free / price(`USDT${e.symbol}`, 1);
    }
    if (e.amount > 0) {
      sumAmount += e.amount / price(`USDT${e.symbol}`, 1);
    }
  })

  return (
    <div className='walletInfo'>
      <span className='forBtc'>
        ≈ {autoFormatter(sumAmount / price('BTCUSDT'))} 
        <b>(<strong>{t('availableWithdraw')}</strong> {autoFormatter(sumFree / price('BTCUSDT'))})</b>
      </span>
      <span className='forUsdt'>
        ≈ {autoFormatter(sumAmount)} 
        <b>(<strong>{t('availableWithdraw')}</strong> {autoFormatter(sumFree)})</b>
      </span>
      <span className='forFiat'>
        ≈ {autoFormatter(sumAmount * price(`USDT${currencyUnit !== 'USD' && currencyUnit}`))} 
        <b>(<strong>{t('availableWithdraw')}</strong> {autoFormatter(sumFree * price(`USDT${currencyUnit}`))})</b> 
        <p>{t('toman')}</p>
      </span>
    </div>
  );
}

export default Info;
