import { ELevel } from 'enums/global';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import Level1 from './Level1';
import Level2 from './Level2';

const LevelUp = () => {
  const { t } = useTranslation();
  const { profile } = useUser();
  // const [canLevelUp, _setCanLevelUp] = useState(true);
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  
  useEffect(() => {
    setCurrent(profile.level);
  }, []);

  return (
    current === ELevel.l0 || current === ELevel.l1 ? (
      current === ELevel.l0 ? <Level1 /> : <Level2 />
    ) : (
      <div className='doneData popup popupFix'>
        <div className='fullBox'>
          <div className='doneIcon errorIcon'></div>
          <h3>{t('cantLevelUp')}</h3>
          <p>{t('cantLevelUpInfo')}</p>
          {/* <p>{t('errorCode')}: 111</p> */}
        </div>
        <div className='share' onClick={() => dispatch(hideDrawer())}>{t('iAmUnderstand')}</div>
      </div>
    )
  )
}

export default LevelUp;
