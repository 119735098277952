import { ICardProps } from 'interfaces/wallet';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { statusColor } from 'utils/color';
import { formatter } from 'utils/numeral';

const SendReceive = ({ value }: ICardProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h3><b className={`color${statusColor(value.status)}`}>{t(value.type)}</b></h3>
        <p>{value.coin}</p>
      </div>
      <div>
        <b><i>{t('amount')}</i> {formatter(value.amount)}</b>
        <b><i>{moment(value.createdAt).locale('fa').format('DD MMM , HH:mm')}</i></b>
      </div>
    </>
  )
}

export default SendReceive;
