import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { bottomDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';
import OpenLogin from './OpenLogin';

const Menu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const path = location.pathname.replace(/\/$/, '');

  const openWallet = (e: any) => {
    if (!isUserLogin()) {
      dispatch(bottomDrawer(<OpenLogin />));
      e.preventDefault();
    }
  }

  return (
    <div className='menu'>
      <Link onClick={(e) => openWallet(e)} className={path === '/wallet' ? 'active' : ''} to='/wallet'>{t('wallet')}</Link>
      <Link className={path === '/trade' ? 'active' : ''} to='/trade'>{t('trade')}</Link>
      <Link className={(path === '/market' || path === '/currency') ? 'active' : ''} to='/market'>{t('market')}</Link>
      <Link className={path === '' ? 'active' : ''} to='/'>{t('home')}</Link>
    </div>
  );
}

export default Menu;
