import Back from 'components/Icons/Back';
import * as d3 from 'd3';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatter } from 'utils/numeral';

const Heatmap = () => {
  const { t } = useTranslation();
  const { price, change, volume } = useTicker();
  const { coins } = useGlobal();

  const colors = {
    '-10000|-5': '#C72A33',
    '-5|-3': '#FF545E',
    '-3|0': '#FF878E',
    '0|3': '#77DBB1',
    '3|5': '#38B781',
    '5|10000': '#0C8954',
  }
  
  const color = (value: number) => {
    if (value >= -0.01 && value <= 0.01) {
      return '#fff'
    } else {
      const x = Object.keys(colors).find((e: string) => { const m = e.split('|'); return value >= Number(m[0]) && value < Number(m[1]); })
      return (colors as any)[x as any];
    }
  }

  useEffect(() => {
    const box = document.querySelector<HTMLElement>('.MuiDrawer-root');
    const width = (box?.offsetWidth || 0) - 30;
    const height = (box?.offsetHeight || 0) - 96;

    const child: any = [];
    if (coins) {
      const dataSort = Object.values(coins).sort((a, b) => {
        return price(coins[b.symbol].symbol + 'USDT') - price(coins[a.symbol].symbol + 'USDT');
      });

      dataSort.forEach((element: any) => {
        const percent = formatter(change(element.symbol + 'USDT'), 2, 2);

        if (percent) {
          child.push({
            name: element.symbol,
            group: 'A',
            value: volume(element.symbol + 'USDT', 1),
            price: price(element.symbol + 'USDT', 1),
            change: percent,
          })
        }
      })
    }

    const svg = d3.select('#dataHeatMap').append('svg').attr('width', width).attr('height', height).append('g').attr('transform', 'translate(0)');
    const root = d3.hierarchy({ children: child }).sum((d: any) => { return d.value}) as any
    d3.treemap().size([width, height]).padding(0)(root)

    svg.selectAll('rect').data(root.leaves()).enter().append('rect')
      .attr('x', (d: any) => { return d.x0; })
      .attr('y', (d: any) => { return d.y0; })
      .attr('width', (d: any) => { return d.x1 - d.x0; })
      .attr('height', (d: any) => { return d.y1 - d.y0; })
      .attr('stroke', 'black')
      .attr('stroke-width', '2')
      .style('fill', (d: any) => { return color(d.data.change)})

    svg.selectAll('text').data(root.leaves()).enter().append('text')
      .text(function(d: any){ return d.data.name })
      .attr('font-family', 'poppins')
      .attr('font-weight', 'bold')
      .attr('font-size', '15px')
      .attr('fill', 'black')
      .attr('x', function(d: any){ return (d.x0 + (d.x1 - d.x0) / 2) - (this.getComputedTextLength() / 2)})
      .attr('y', function(d: any){ return (d.y0 + (d.y1 - d.y0) / 2) - ((d.x1 - d.x0) < 60 || (d.y1 - d.y0) < 60 ? -5 : 10) })
      .style('display', function(d: any){ return (d.x1 - d.x0) < this.getComputedTextLength() || (d.y1 - d.y0) < this.getComputedTextLength() ? 'none' : '' });

    svg.selectAll('text').append('tspan')
      .text(function(d: any){ return d.data.price })
      .attr('font-size', '13px')
      .attr('font-weight', 'normal')
      .attr('x', function(d: any){ return (d.x0 + (d.x1 - d.x0) / 2) - (this.getComputedTextLength() / 2)})
      .attr('y', function(d: any){ return (d.y0 + (d.y1 - d.y0) / 2) + 5 })
      .style('display', function(d: any){ return (d.x1 - d.x0) < 60 || (d.y1 - d.y0) < 60 ? 'none' : '' });

    svg.selectAll('text').append('tspan')
      .text(function(d: any){ return d.data.change + '%' })
      .attr('font-size', '13px')
      .attr('font-weight', 'normal')
      .attr('x', function(d: any){ return (d.x0 + (d.x1 - d.x0) / 2) - (this.getComputedTextLength() / 2)})
      .attr('y', function(d: any){ return (d.y0 + (d.y1 - d.y0) / 2) + 20 })
      .style('display', function(d: any){ return (d.x1 - d.x0) < 60 || (d.y1 - d.y0) < 60 ? 'none' : '' });
  }, []);

  return (
    <div className='popup heatmap'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('heatmap')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div id='dataHeatMap'></div>
    </div>
  )
}

export default Heatmap;
