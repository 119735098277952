import config from 'config';
import { IForm } from 'interfaces/login';
import { useTranslation } from 'react-i18next';
import { numbersToEn } from 'utils/tools';

const Form = ({ mobile, setMobile, auth }: IForm) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='logoNew'></div>
      <h2>{t('registerLogin')}</h2>
      <div className='parentOfInput'>
        <input onChange={(e) => setMobile(numbersToEn(e.target.value))} placeholder={`${t('registerLoginInfo')}`} type='tel' value={mobile} autoFocus />
      </div>
      <div className='share' onClick={() => auth()}>{t('submit')}</div>
      <span>{t('accept')} <a href={`${config.site}/faq`} target='_blank'>{t('dexRule')}</a> {t('and')} <a href={`${config.site}/privacy`} target='_blank'>{t('privacy')}</a></span>
    </>
  )
}

export default Form;
