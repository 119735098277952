import { ETicker } from 'enums/global';
import { ISelector } from 'interfaces/global';
import { useSelector } from 'react-redux';

const useTicker = () => {
  const selector = useSelector((state: ISelector) => state.ticker);
  
  const get = (key: string, child: ETicker = ETicker.price, def: number = 0) => {
    return selector.tickers[key]?.[child] || def;
  }

  const price = (key: string, def: number = 0) => {
    return get(key, ETicker.price, def);
  }

  const volume = (key: string, def: number = 0) => {
    return get(key, ETicker.volume, def);
  }

  const change = (key: string, def: number = 0) => {
    return get(key, ETicker.change, def);
  }

  const value = (key: string, def: number = 0) => {
    return get(key, ETicker.volume, def);
  }

  const high = (key: string, def: number = 0) => {
    return get(key, ETicker.high, def);
  }

  const low = (key: string, def: number = 0) => {
    return get(key, ETicker.low, def);
  }

  return { 
    price,
    volume,
    change,
    value,
    high,
    low,
  }
}

export default useTicker;
