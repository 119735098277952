import { ISearch } from 'interfaces/layout';
import { useTranslation } from 'react-i18next';

const SearchBox = ({ setSearch }: ISearch) => {
  const { t } = useTranslation();

  return (
    <div className='search'>
      <input type='text' onChange={(e) => setSearch(e.target.value)} placeholder={`${t('search')}`} />
      <input type='submit' value='' />
    </div>
  );
}

export default SearchBox;
