import SearchBox from 'components/Layouts/SearchBox';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import { autoFormatter, formatter } from 'utils/numeral';
import Account from './Account';
import Wallet from './Wallet';

const Balance = () => {
  const { t } = useTranslation();
  const { coins, currencyUnit, currencies } = useGlobal();
  const { balance } = useUser();
  const { price } = useTicker();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [hide, setHide] = useState(Cookies.get('hideOne') === 'true' ?? false);
  const [open, setOpen] = useState(false);
  
  const changeHide = () => {
    setHide(!hide);
    Cookies.set('hideOne', (!hide).toString(), { expires: 30 * 24 * 60 * 60, path: '/' });
  }

  const wallet = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(balance.wallet).filter(e => e.symbol.toLowerCase().includes(search.toLowerCase()));
    find.forEach((value, i) => {
      const element = coins[value.symbol];
      const priceUsdt = price(`${element.symbol}USDT`, 1);
      const priceTmn = price(`${element.symbol}${currencyUnit}`, 1);

      if ((hide && priceUsdt * value.amount >= 1) || !hide) {
        rows.push(
          <div onClick={() => dispatch(bottomDrawer(<Wallet symbol={element.symbol} />))} key={i}>
            <img src={element.icon} alt='' />
            <div>
              <h2>{element.symbol}</h2>
              <h3>{autoFormatter(value.amount)}</h3>
              <h3><i><strong>{t('availableWithdraw')}</strong> {formatter(value.free, element.decimal)}</i></h3>
            </div>
            <div>
              <p>≈ {autoFormatter(priceTmn * value.free)} {currencyUnit}</p>
              <p>≈ {autoFormatter(priceUsdt * value.free)} USDT</p>
            </div>
            <span className='showDetail'></span>
          </div>
        );
      }
    });

    return rows;
  }

  const account = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(balance.account).filter(e => e.symbol.toLowerCase().includes(search.toLowerCase()));
    find.forEach((value, i) => {
      const element = currencies[value.symbol];
      const priceUsdt = price(`USDT${value.symbol}`, 1);
      
      if ((hide && value.free / priceUsdt >= 1) || !hide) {
        rows.push(
          <div onClick={() => dispatch(bottomDrawer(<Account symbol={value.symbol} />))} key={i}>
            <img src={element.icon} alt='' />
            <div>
              <h2>{value.symbol}</h2>
              <h3>{autoFormatter(value.amount)}</h3>
              <h3><i><strong>{t('availableWithdraw')}</strong> {formatter(value.free, element.decimal)}</i></h3>
            </div>
            <div><p>≈ {autoFormatter(value.free / priceUsdt)} USDT</p></div>
            <span className='showDetail'></span>
          </div>
        );
      }
    })

    return rows;
  }

  return (
    <>
      <div className='lineSep'></div>
      <div className='walletSearch'>
        <div className={`hideLessOne hideLessOne_${hide}`} onClick={() => changeHide()}>{t('hideLessOne')}</div>
        <span className={`blueSearch blueSearch_${open}`} onClick={() => setOpen(!open)}></span>
        <SearchBox setSearch={setSearch} />
      </div>
      <section className='myCurrency'>
        {(wallet().length > 0 || account().length > 0) ? (
          <>
            {account()}
            {wallet()}
          </>
        ) : (
          <span className='notFound'>{t('notFound')}</span>
        )}
      </section>
    </>
  )
}

export default Balance;
