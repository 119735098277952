import { IPercentage } from 'interfaces/helper';

const Percentage = ({ percentage, setPercentage }: IPercentage): React.ReactElement => {
  const rows: React.ReactElement[] = [];

  for (let i = 25; i <= 100; i += 25) {
    rows.push(<span key={i} onClick={() => setPercentage(i)} className={`${percentage === i && 'active'}`}>{i}%</span>);
  };

  return (<div className='mainTab secondTab percentTab'>{rows}</div>);
}

export default Percentage;
