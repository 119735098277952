import Tabs from 'components/Helper/Tabs';
import Back from 'components/Icons/Back';
import CoinList from 'components/Layouts/CoinList';
import { ETransaction } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import { IExpressProps } from 'interfaces/express';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { leftDrawer } from 'services/reducer/drawer';
import Form from './Form';

const Express = ({ value }: IExpressProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { coins } = useGlobal();
  const [symbol, setSymbol] = useState(value)
  const [type, setType] = useState(ETransaction.buy);

  const typeTitle = {
    [ETransaction.sell]: t('sell'),
    [ETransaction.buy]: t('buy'),
  };
  
  const data = coins[symbol];

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('expressBuy')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
      <div className='mainTab mainTabExpress'>
        <Tabs titles={typeTitle} selected={type} setSelected={setType} />
      </div>
        <div className='box boxMarket boxReceive' onClick={() => dispatch(leftDrawer(<CoinList setSelect={setSymbol} />))}>
          <img src={data.icon} alt='' />
          {data.name}
        </div>
        <Form data={data} type={type} />
      </div>
    </div>
  )
}

export default Express;
