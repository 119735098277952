import { IForm } from 'interfaces/card';

const CardInput = ({ setCard, card }: IForm) => {
  const space = '                  ';

  return (
    <div className='box boxMarket boxSingle boxCard'>
      <input 
        maxLength={70} 
        placeholder={'----' + space + '----' + space + '----' + space + '----'} 
        onChange={(e) => setCard(e.target.value.replace(/\D/g, ''))} 
        value={card && card.match(new RegExp('.{1,4}', 'g'))?.join(space)}
      />
    </div>
  );
}

export default CardInput;
