import config from 'config';
import { IDispatch } from 'interfaces/global';
import Cookies from 'js-cookie';
import moment from 'moment';
import { getFavorites } from 'services/reducer/favorite';
import { getAccountBalance, getCards, getFiles, getProfile, getWalletBalance } from 'services/reducer/user';
import gather from './gather';

const isUserLogin = () => {
  if (Cookies.get('accessToken') && Cookies.get('refreshToken')) {
    return true;
  }
  return false;
}

const logoutUser = (redirect = false, fromServer = false) => {
  if (fromServer) {
    gather(`${config.api}/api/v1/account/user/logout`, true).post();
  }

  Cookies.remove('accessToken', { path: '/' });
  Cookies.remove('refreshToken', { path: '/' });
  Cookies.remove('socketToken', { path: '/' });

  if (redirect) {
    window.location.reload();
  }
}

const checkAuth = async (auto: boolean = false) => {
  const time = moment().format('YYYY-MM-DD hh:mm:ss')
  const refreshTime = Cookies.get('refreshTime') ?? time

  if ((time >= refreshTime || auto) && Cookies.get('refreshToken')) {
    const result = await gather(`${config.api}/api/v1/account/auth/refresh`).get({
      authorization: Cookies.get('refreshToken'),
    });

    if (result) {
      if (result.code === 200) {
        Cookies.set('accessToken', result.data.tokens.access, { expires: 30 * 24 * 60 * 60, path: '/' });
      } else {
        logoutUser();
      }

      Cookies.set('refreshTime', moment().add(10, 'm').format('YYYY-MM-DD hh:mm:ss'))
    } else {
      await new Promise(resolve => setTimeout(resolve, 5000));
      await checkAuth(auto);
    }
  }
}

const getUserData = async (dispatch: IDispatch) => {
  await Promise.all([
    dispatch(getProfile()),
    dispatch(getFiles()),
    dispatch(getAccountBalance()),
    dispatch(getWalletBalance()),
    dispatch(getFavorites()),
    dispatch(getCards()),
  ]);
}

export {
  checkAuth,
  getUserData,
  isUserLogin,
  logoutUser
};
