import Back from 'components/Icons/Back';
import CardInput from 'components/Layouts/CardInput';
import { IDispatch, IResponse } from 'interfaces/global';
// import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { addCard } from 'services/reducer/user';

const NewCard = () => {
  const { t } = useTranslation();
  const [card, setCard] = useState('');
  const dispatch = useDispatch<IDispatch>();

  const send = async () => {
    dispatch(showLoading());

    const result = (await dispatch(addCard({
      number: card,
    }))).payload as IResponse;

    if (result.code === 200) {
      dispatch(successAlert(t('addCardSuccessful')));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('addCard')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
        <p className='description'>{t('cardPersonal')}</p>
        <CardInput setCard={setCard} card={card} />
        <div className='showData'>
          <div className='dataShare'></div>
          <div className='share' onClick={() => send()}>{t('submit')}</div>
        </div>
      </div>
    </div>
  );
}

export default NewCard;
