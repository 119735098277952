import DatePicker from 'components/Helper/DatePicker';
import Back from 'components/Icons/Back';
import CardInput from 'components/Layouts/CardInput';
import config from 'config';
import { ELevel } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { IDispatch, IResponse } from 'interfaces/global';
import { IForm } from 'interfaces/levelUp';
import moment from 'jalali-moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { addCard, updateProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import Card1 from './Card1';

const Form = ({ setIsDone }: IForm) => {
  const { t } = useTranslation();
  const { profile } = useUser();
  const [step, setStep] = useState(profile?.nationalCode && profile?.nationalCode ? 1 : 0);
  const { levels } = useGlobal();
  const [card, setCard] = useState('');
  const [national, setNational] = useState(profile?.nationalCode);
  const [birthday, setBirthday] = useState(profile?.birthDate ? moment(profile?.birthDate, 'YYYY-MM-DD').locale('fa').format('YYYY-MM-DD') : '');
  const dispatch = useDispatch<IDispatch>();

  const data = Object.values(levels).find((e) => e.type === ELevel.l1);

  const list = () => {
    const rows: React.ReactElement[] = [];

    [t('completeAccount'), t('bankDetail')].forEach((value, key) => {
      rows.push(
        <div key={key} className={`${key <= step && 'active'}`}>
          <span>{t('step')} {(key + 1)}</span>
          <p>{value}</p>
        </div>
      );
    });
    
    return rows;
  }

  const finish = async () => {
    dispatch(showLoading());

    const result = (await dispatch(addCard({
      number: card,
    }))).payload as IResponse;

    if (result.code === 200) {
      // Upgrade user after send card
      const result = await gather(`${config.api}/api/v1/account/user/upgrade`, true).post();
      if (result.code === 200) {
        setIsDone(true);
      } else {
        dispatch(errorAlert(t(result?.message) || t('errorFound')));
      }
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const send = async () => {
    dispatch(showLoading());

    const body = {
      nationalCode: national,
      birthDate: moment.from(birthday, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD'),
    }

    const result = await gather(`${config.api}/api/v1/account/user/validation`, true).post(body);
    if (result.code === 200) {
      dispatch(updateProfile(body));
      setStep(1);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }
  
  return (
    <>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('verifyLevel')} {data?.title}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='listOfLevel'>{list()}</div>
      <div className='showData'>
        {step === 0 ? (
          <>
            <div className='loginContent verifyForm'>
              <input onChange={(e) => setNational(e.target.value)} className='ltr' defaultValue={national} placeholder={`${t('nationalCode')}`} type='number' />
              <div className='parentPassword' onClick={() => dispatch(bottomDrawer(<DatePicker defaultValue={birthday} setDatePicker={setBirthday} />))}>
                <input value={birthday} readOnly className='ltr' placeholder={`${t('birthday')}`} type='text' />
                <i className='datePickerIcon'></i>
              </div>
            </div>
            <Card1 />
          </>
        ) : (
          <>
            <CardInput setCard={setCard} card={card} />
            <div className='infoText'>
              <span></span>
              <p>{t('nationalCodeInfo')}: <b className='colorBlue'>{profile.nationalCode}</b></p>
            </div>
          </>
        )}
      </div>
      <div className='bottomVerify'>
        <div className='share' onClick={() => step === 0 ? send() : finish()}>{t('addAndContinue')}</div>
      </div>
    </>
  );
}

export default Form;
