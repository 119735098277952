import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingShow: false,
}

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoadingShow = true
    },
    hideLoading: (state) => {
      state.isLoadingShow = false
    }
  }
});

export const { 
  showLoading, 
  hideLoading,
} = slice.actions;

export default slice.reducer;
