import { CircularProgress } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import Close from 'components/Icons/Close';
import Deposit from 'components/Modules/Deposit';
import Withdraw from 'components/Modules/Withdraw';
import config from 'config';
import { ETransaction } from 'enums/global';
import { EMonth, ETab } from 'enums/wallet';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IAccountProps, IList } from 'interfaces/wallet';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import Chart from './Chart';
import Data from './Data';

const Account = ({ symbol }: IAccountProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { price } = useTicker();
  const { balance } = useUser();
  const [tab, setTab] = useState(ETab.buySell);
  const [month, setMonth] = useState(EMonth.m1);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IList[]>([]);
  const [from, setFrom] = useState(Math.floor(moment().subtract(month * 30, 'd').valueOf()));

  const tabTitle = {
    [ETab.buySell]: t('buyAndSell'),
    [ETab.deposit]: t('charge'),
    [ETab.withdraw]: t('withdraw'),
  }

  const tabMonth = {
    [EMonth.m1]: `1 ${t('pastMonth')}`,
    [EMonth.m3]: `3 ${t('pastMonth')}`,
    [EMonth.m6]: `6 ${t('pastMonth')}`,
  }

  const userBalance = balance.account[symbol];

  const open = (content: JSX.Element) => {
    dispatch(hideLoading());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 250);
  }

  const getData = async (address: string, type?: ETransaction) => {
    const result = await gather(`${config.api}/api/v1/${address}?from=${from}&to=${Math.floor(moment().add(10, 'm').valueOf())}&currency=${symbol}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) => Object.assign({ tab: tab, [type ? 'type' : '']: type }, e));
    }
    return [];
  }

  const getList = async () => {
    setLoading(true);
    setList([]);
    
    const result = await Promise.all([
      getData('account/deposit', ETransaction.deposit),
      getData('account/withdraw', ETransaction.withdraw),
      getData('capital/order'),
    ]).then((results) => {
      return [].concat.apply([], [results[0], results[1], results[2]]);
    }) as IList[];

    setLoading(false);
    setList(result.sort((b, a) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
  }

  useEffect(() => {
    getList();
  }, [from]);

  const changeMonth = (value: number) => {
    setMonth(value);
    setFrom(Math.floor(moment().subtract(value * 30, 'd').valueOf()));
  }
  
  return (
    <div className='popup popupDown'>
      <Close />
      <div className='tabTrans tabTransDetail'>
        <div className='subTab'><Tabs titles={tabMonth} selected={month} setSelected={changeMonth} /></div>
      </div>
      <Chart data={list} />
      <div className='headDetail'>
        <h6>{autoFormatter(userBalance.amount)}</h6>
        <p>{t('canWithdraw')}: {autoFormatter(userBalance.free)}</p>
        <p>≈ {autoFormatter(userBalance.free / price(`USDT${symbol}`, 1))} USDT</p>
      </div>
      <div className='detailTab'>
        <div>
          <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
        </div>
      </div>
      <div className='bodyDetail'>
        {loading ? (
          <div className='loadingParent'><CircularProgress size={20} /></div>
        ) : (
          <Data list={list} tab={tab} />
        )}
      </div>
      <section className='parentShare parentDetail parentDetailCurrency'>
        <div className='share' onClick={() => open(<Withdraw value={symbol} />) }>{t('withdraw')}</div>
        <div className='share' onClick={() => open(<Deposit />) }>{t('charge')}</div>
      </section>
    </div>
  )
}

export default Account;
