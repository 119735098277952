import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { autoFormatter } from 'utils/numeral';

const SendReceive = ({ value }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address)
    dispatch(successAlert(t('copied')));
  }

  return (
    <>
      <span><i>{t('amount')}</i><b>{autoFormatter(value.amount)}</b></span>
      {value.type === 'send' && <span><i>{t('fee')}</i><b>{autoFormatter(value.fee)}</b></span>}
      <span><i>{t('network')}</i><b>{value.network}</b></span>
      {value.type === 'send' && <span><i>{t('toAddrress')}</i><b>{value.to}</b></span>}
      {value.hash && <span><i>TXID</i><div className='copyHash' onClick={() => copyAddress(value.hash)}></div><b>{value.hash}</b></span>}
    </>
  );
}

export default SendReceive;
