import Tabs from 'components/Helper/Tabs';
import { ETab } from 'enums/bill';
import { EStatus, ETransaction } from 'enums/global';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ListTab = ({ tab, setTab, filter, setFilter }: any) => {
  const { t } = useTranslation();

  const tabTitle = {
    [ETab.all]: t('all'),
    [ETab.trade]: t('trades'),
    [ETab.transaction]: t('transactions'),
  }

  const tradeTitle = {
    [EStatus.pending]: t('pending'),
    [EStatus.complete]: t('complete'),
    [EStatus.cancel]: t('cancel'),
  }

  const transTitle = {
    [ETransaction.receive]: t('receive'),
    [ETransaction.send]: t('send'),
    [ETransaction.convert]: t('convert'),
    [ETransaction.withdraw]: t('withdraw'),
    [ETransaction.deposit]: t('charge'),
  }

  useEffect(() => {
    setFilter()
  }, [tab]);

  return (
    <div className='tabTrans'>
      <div>
        <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
      </div>
      <div className='subTab'>
        {tab !== ETab.all && (
          <Tabs titles={tab === ETab.trade ? tradeTitle : transTitle} selected={filter} setSelected={setFilter} />
        )}
      </div>
    </div>
  );
}

export default ListTab;
