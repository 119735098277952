import Menu from 'components/Layouts/Menu';
import PairList from 'components/Layouts/PairList';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { IOrderList } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { leftDrawer } from 'services/reducer/drawer';
import { priceColor } from 'utils/color';
import { formatter } from 'utils/numeral';
import { refreshPage } from 'utils/tools';
import Bot from './Bot';
import Direct from './Direct';
import OpenOrder from './OpenOrder';
import OrderBook from './OrderBook';

const Trade = () => {
  const dispatch = useDispatch();
  const { pairs, currencyUnit } = useGlobal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [symbol, setSymbol] = useState(searchParams.get('symbol') ?? (config.source === 'corypton' ? `BTCUSDT` : `USDT${currencyUnit}`));
  const [robot, setRobot] = useState(false);
  const { change } = useTicker();
  const [orderList, setOrderList] = useState<IOrderList>({ buy: [], sell: [] });

  const data = pairs[symbol];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSearchParams(params => {
      params.set('symbol', symbol);
      return params;
    });
  }, [symbol]);

  return (
    <>
      <Menu />
      <PullToRefresh onRefresh={() => refreshPage()}>
        <>
          <div className='main'>
            <header className='header headerTrade'>
              <h1 className={`robot_spot_${robot}`} onClick={() => setRobot(false)}>Spot</h1>
              {/* <span className={'robot robot_' + robot + ''} onClick={() => setRobot(!robot)}></span> */}
            </header>
            <section className='burger'>
              <span onClick={() => dispatch(leftDrawer(<PairList setSelect={setSymbol} />))}></span>
              <h2>{`${data.base}/${data.quote}`}</h2>
              <p className={`color${priceColor(change(symbol))}`}>{formatter(change(symbol), 2, 2)}%</p>
            </section>
            <section className='tradeList'>
              <div className='tradeForm'>
                {robot ? <Bot symbol={symbol} data={data} /> : <Direct symbol={symbol} data={data} />}
              </div>
              <OrderBook setOrderList={setOrderList} orderList={orderList} data={data} />
            </section>
            <OpenOrder />
          </div>
          <div className='extraBottom'></div>
        </>
      </PullToRefresh>
    </>
  );
}

export default Trade;
