import Tabs from 'components/Helper/Tabs';
import config from 'config';
import { ESection } from 'enums/currency';
import { ETransaction } from 'enums/global';
import useTicker from 'hooks/useTicker';
import { IDeepDataProps, ISlot } from 'interfaces/currency';
import { IBuySell, IDepth } from 'interfaces/global';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';

const DeepData = ({ symbol, data, orderList }: IDeepDataProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ESection.deep);
  const [slot, setSlot] = useState<ISlot[]>([]);
  const { price } = useTicker();

  const tabTitle = {
    [ESection.deep]: t('deep'),
    [ESection.trades]: t('trades'),
  }

  const buyMax = Number(Math.max(...orderList.buy.map((e) => e.price)));
  const sellMax = Number(Math.min(...orderList.sell.map((e) => e.price)));

  const depth = (data: IBuySell[]) => {
    const result: IDepth[] = [];
    
    let sum = 0;
    data.forEach((value) => {
      sum += Number(value.amount);
      result.push({
        s: sum,
      })
    });

    return result;
  }
  
  const list = () => {
    const rows: React.ReactElement[] = [];

    slot.slice(0, 10).forEach((value, i) => {
      rows.push(
        <div key={i} className='reqValues'>
          <span>{autoFormatter(value.receive)}</span>
          <span className={value.type === ETransaction.buy ? 'colorGreen' : 'colorRed'}>{autoFormatter(value.price)}</span>
          <span>{moment(value.createdAt).format('HH:mm:ss')}</span>
        </div>
      );
    });

    return rows;
  }

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/capital/order/trades/${symbol}`).get();
    if (result.code === 200) {
      setSlot(result.data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='deepData'>
      <div className='infoLatest'>
        <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
      </div>
      {tab === ESection.trades ? (
        <>
          <div className='reqValues'>
            <span>{t('amount')} ({data.base})</span>
            <span>{t('price')} ({data.quote})</span>
            <span>{t('time')}</span>
          </div>
          <div className='tradeTable'>
            {list()}
          </div>
        </>
      ) : (
        <>
          <div className='bothDeep'>
            <ResponsiveContainer height='100%' width='100%'>
              <AreaChart data={depth(orderList.sell)} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray='3 3' opacity={0.08}  />
                <Area dataKey='s' stroke='#FF545E' fill='#43252B' fillOpacity={0.9} strokeWidth={1} />
              </AreaChart>
            </ResponsiveContainer>
            <ResponsiveContainer height='100%' width='100%'>
              <AreaChart data={depth(orderList.buy)} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray='3 3' opacity={0.08}  />
                <Area dataKey='s' stroke='#38B781' fill='#1E3B34' fillOpacity={0.9} strokeWidth={1} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className='reqValues reqValuesDeep'>
            <span>{autoFormatter(buyMax)}</span>
            <span>{autoFormatter(price(symbol))}</span>
            <span>{autoFormatter(sellMax)}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default DeepData;
