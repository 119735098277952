import { CircularProgress } from '@mui/material';
import OptionForm from 'components/Helper/OptionForm';
import Back from 'components/Icons/Back';
import CoinList from 'components/Layouts/CoinList';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { useQRCode } from 'next-qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { bottomDrawer, leftDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';

const Receive = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState(value);
  const [network, setNetwork] = useState('');
  const { Image } = useQRCode();
  const dispatch = useDispatch();
  const { coins } = useGlobal();

  const data = coins[symbol];
  
  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert(t('walletCopied')));
  }

  const share = async () => {
    try {
      await navigator.share({
        title: `My ${symbol} QR code on ${value} network`,
        text: address,
        url: config.site,
      });
    } catch (err) {
      // dispatch(errorAlert(t('errorFound')));
    }
  }

  const getData = async () => {
    setLoading(true);

    const result = await gather(`${config.api}/api/v1/capital/receive/address`, true).post({
      coin: symbol,
      network: network,
    });

    if (result.code === 200) {
      setAddress(result.data.address);
    } else {
      setAddress('');
      setNetwork('');
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    setLoading(false);
  }

  useEffect(() => {
    network && getData();
  }, [network]);

  useEffect(() => {
    setAddress('');
    setNetwork('');
  }, [symbol]);

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('receive')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
        <div className='tradeForm'>
          <div className='box boxMarket boxReceive' onClick={() => dispatch(leftDrawer(<CoinList setSelect={setSymbol} />))}>
            <img src={data.icon} alt='' />
            {data.name}
          </div>
          <div className='box boxMarket boxReceive righTextSelect dropIcon' onClick={() => dispatch(bottomDrawer(<OptionForm value={data?.networks?.map(e => e.symbol).reduce((ac: any, a: any) => ({ ...ac, [a]: a }), {})} def={network} setOption={setNetwork} />))}>
            {!network ? t('selectNetwork') : network}
          </div>
          {loading ? (
            <div className='loadingParent'>
              <CircularProgress color='info' size={30} />
            </div>
          ) : (
            <div className='showData'>
              {address ? (
                <>
                  <div className='dataShare'>
                    <Image text={address} options={{ type: 'image/jpeg', quality: 1, margin: 2, width: 300 }} />
                    <div className='box boxMarket qrAddress'>
                      <span onClick={() => copyAddress()}></span>
                      <input defaultValue={address} disabled={true}/>
                    </div>
                  </div>
                  <div className='share' onClick={() => share()}>{t('share')}</div>
                </>
              ) : (
                <div className='loadingParent'>
                  <div className='notFound'>{t('selectANetwork')}</div>
                </div>
              )}
            </div>
          )}
        </div>
    </div>
  )
}

export default Receive;
