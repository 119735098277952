import Tabs from 'components/Helper/Tabs';
import { ETab } from 'enums/home';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { priceColor } from 'utils/color';
import { autoFormatter, formatter } from 'utils/numeral';

const MainList = () => {
  const MAX = 5;
  const { t } = useTranslation();
  const { coins, currencyUnit } = useGlobal();
  const { price, change } = useTicker();
  const [maxShow, setMaxShow] = useState(MAX);
  const [tab, setTab] = useState(ETab.value);
  
  const tabTitle = { 
    [ETab.value]: t('mostValue'), 
    [ETab.grow]: t('mostGrow'), 
    [ETab.fall]: t('mostFall'),
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    let data = Object.values(coins).sort((a, b) => price(`${b.symbol}USDT`) - price(`${a.symbol}USDT`));
    if (tab === ETab.grow || tab === ETab.fall) {
      data = data.sort((a, b) => change(`${b.symbol}USDT`) - change(`${a.symbol}USDT`));
      if (tab === ETab.fall) {
        data.reverse();
      }
    }

    data = data.slice(0, maxShow);

    data.forEach((element, i) => {
      const percent = change(`${element.symbol}USDT`);
      const hue = priceColor(percent);

      rows.push(
        <Link to={`/currency/?symbol=${element.symbol.toUpperCase()}TMN`} className='currencyList' key={i}>
          <span><i className={`bg${hue}Percent color${hue}`}>{formatter(percent, 2, 2)}%</i></span>
          <span>
            ${autoFormatter(price(`${element.symbol}USDT`, 1))}
            <b>{autoFormatter(price(`${element.symbol}${currencyUnit}`, 1))}<i>{currencyUnit}</i></b>
          </span>
          <span><img src={element.icon} alt='' /> {element.name}</span>
        </Link>
      );
    });

    return rows;
  }

  return (
    <section className='currency'>
      <div className='currencyTitle'>
        <Tabs titles={tabTitle} setSelected={setTab} selected={tab} />
      </div>
      {list()}
      {(Object.values(coins).length > MAX && maxShow === MAX) && (
        <div className='showMore' onClick={() => setMaxShow(1000)}>{t('showMore')} »</div>
      )}
    </section>
  )
}

export default MainList;
