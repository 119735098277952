import { CircularProgress } from '@mui/material';
import config from 'config';
import { ETab } from 'enums/priceAlert';
import { IAlarm } from 'interfaces/priceAlert';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter } from 'utils/numeral';

const Data = ({ tabKind, addNew }: any) => {
  const { t } = useTranslation();
  const [list, setList] = useState<IAlarm[]>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const disable = async (id: string, isActive: boolean) => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/alarm/${id}/enable`, true).put({
      isEnable: !isActive,
    });

    if (result.code === 200) {
      getList();
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const remove = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/live/alarm/${id}`, true).delete();
    if (result.code === 200) {
      getList();
      dispatch(successAlert(t('successful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  const showData = () => {
    const rows: React.ReactElement[] = [];

    if (list.length > 0) {
      list.forEach((value, i) => {
        rows.push(
          <div key={i}>
            <i onClick={() => remove(value.id)}></i>
            <h6>{value.symbol}</h6>
            <span>{value.type === ETab.swing ? t('swingAlert') : tabKind[value.kind]}</span>
            <span>{formatter(value.value)} {value.type === ETab.swing && '%'}</span>
            <div className='passwordList'>
              <span onClick={() => disable(value.id, value.isEnable)}>
                <i className={value.isEnable ? 'active' : ''}></i>
              </span>
            </div>
          </div>
        )
      });
    } else {
      rows.push(<span className='notifData notFound' key={1}>{t('notFound')}</span>);
    }
    
    return rows;
  }

  const getList = async () => {
    setLoading(loading);

    const result = await gather(`${config.api}/api/v1/live/alarm`, true).get();
    if (result.code === 200) {
      setList(result.data);
    }

    setLoading(false);
  }

  useEffect(() => {
    getList();
  }, [addNew]);

  return (
    loading ? (
      <div className='loadingParent'>
        <CircularProgress color='info' size={30} />
      </div>
    ) : (
      <div className='alertList'>
        {showData()}
      </div>
    )
  )
}

export default Data;
