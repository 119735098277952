import LevelUp from 'components/Modules/LevelUp';
import Receive from 'components/Modules/Receive';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';

const NoBalance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useUser();

  const open = (content: any) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 250);
  }
  
  return (
    <div className='popup popupDown'>
      <div className='alertIcon'>
        <span></span>
        <p>{t('noBalance')}</p> 
      </div>
      <p className='alertText'>{profile.level === 0 ? t('noBalanceInfo') : t('noBalanceInfoLevel')}</p>
      <div className='parentShare'>
        {profile.level !== 0 ? (
          <div className='share' onClick={() => dispatch(hideDrawer())}>{t('iAmUnderstand')}</div>
        ) : (
          <>
            <div className='share' onClick={() => open(<Receive value='' />)}>{t('receiveCrypto')}</div>
            <div className='share' onClick={() => open(<LevelUp />)}>{t('goToNextLevel')}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default NoBalance;
