import OptionForm from 'components/Helper/OptionForm';
import AddressUpload from 'components/Layouts/AddressUpload';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { IStep } from 'interfaces/levelUp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Address = ({ setStep }: IStep) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useUser();
  const { states } = useGlobal();
  const [state, setState] = useState(profile?.address?.state)
  const [city, setCity] = useState(profile?.address?.city)
  const [fullAddress, setFullAddress] = useState(profile?.address?.line)
  const [zipCode, setZipCode] = useState(profile?.address?.zipCode)
  const [phone, setPhone] = useState(profile?.address?.phone)
  const [prefixPhone, setPrefixPhone] = useState(profile?.address?.prefix)

  const statesData = states.reduce((a: { [k: string]: string }, c) => (a[c.slug] = c.title, a), {});
  const citiesData = states.find(e => e.slug === state)?.city.reduce((a: { [k: string]: string }, c) => (a[c.slug] = c.title, a), {});

  const send = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/user/address`, true).put({
      state: state,
      city: city,
      line: fullAddress,
      zipCode: zipCode,
      phone: phone,
      prefix: prefixPhone,
    });

    if (result.code === 200) {
      setStep(3);
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='showData'>
      <div className='loginContent verifyForm addressForm'>
        <div className='parentInfoBox'>
          <div className='infoBoxRight infoBoxRightText' onClick={() => dispatch(bottomDrawer(<OptionForm value={statesData} def={''} setOption={setState} />))}>{state || t('selectState')}</div>
          <div className='infoBoxRight infoBoxRightText' onClick={() => state && dispatch(bottomDrawer(<OptionForm value={citiesData} def={''} setOption={setCity} />))}>{city || t('selectCity')}</div>
        </div>
        <textarea onChange={(e) => setFullAddress(e.target.value)} defaultValue={fullAddress} placeholder={`${t('fullAddress')}`}></textarea>
        <input onChange={(e) => setZipCode(e.target.value)} defaultValue={zipCode} className='ltr' placeholder={`${t('zipCode')}`} type='number' />
        <div className='parentInfoBox parentPhoneBox'>
          <input onChange={(e) => setPhone(e.target.value)} className='ltr' defaultValue={phone} placeholder={`${t('phone')}`} type='number' />
          <input onChange={(e) => setPrefixPhone(e.target.value)} defaultValue={prefixPhone} className='ltr' placeholder={`${t('prefixPhone')}`} type='number' />
        </div>
      </div>
      <div className='lineSep lineSepTop'></div>
      <AddressUpload />
      <div className='bottomVerify'>
        <div className='share' onClick={() => send()}>{t('addAndContinue')}</div>
      </div>
    </div>
  );
}

export default Address;
