import { EColor, EStatus, ETransaction } from 'enums/global';

const cardColor = (type: ETransaction, status: EStatus) => {
  if (status === EStatus.pending || status === EStatus.uphold) {
    return EColor.orange;
  } else if (status === EStatus.cancel) {
    return EColor.gray;
  }

  if (type === ETransaction.buy) {
    return EColor.green;
  } else if (type === ETransaction.sell) {
    return EColor.red;
  } else if (Object.values(ETransaction).includes(type)) {
    return EColor.blue;
  }

  return EColor.gray;
}

const statusColor = (status: EStatus) => {
  if (status === EStatus.cancel || status === EStatus.reject) {
    return EColor.red;
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return EColor.green;
  } else if (status === EStatus.partial) {
    return EColor.blue;
  } else if (status === EStatus.pending) {
    return EColor.orange;
  }
  return EColor.gray;
}

const typeColor = (type: ETransaction) => {
  if (type === ETransaction.sell) {
    return EColor.red;
  } else if (type === ETransaction.buy) {
    return EColor.green;
  }
  return EColor.gray;
}

const priceColor = (price: number) => {
  if (price > 0) {
    return EColor.green;
  } else if (price < 0) {
    return EColor.red;
  }
  return EColor.light;
}

export {
  cardColor,
  priceColor,
  statusColor,
  typeColor
};
