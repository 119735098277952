import config from 'config';
import { ETransaction } from 'enums/global';
import { IOrderBookProps } from 'interfaces/currency';
import { IBuySell } from 'interfaces/global';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { socket } from 'services/socket';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';

const OrderBook = ({ symbol, orderList, setOrderList }: IOrderBookProps) => {
  const { t } = useTranslation();

  const list = (data: IBuySell[]) => {
    const rows: React.ReactElement[] = [];

    const max = Math.max(...data.map((e) => e.quantity));
    const min = Math.min(...data.map((e) => e.quantity));

    data.forEach((value, i) => {
      let percent = ((Number(value.quantity) - min) / (max - min)) * 100;
      if (percent < 1) {
        percent = 1;
      }

      rows.push(
        <span key={i}>
          <b style={{ width: `${percent}%` }}></b>
          <i>{autoFormatter(Number(value.amount))}</i>
          {autoFormatter(Number(value.price))}
        </span>
      );
    });

    for (let i = rows.length; i < 10; i++) {
      rows.push(<span key={1000 + i} className='noData'><b></b><i>0.0</i>0.0</span>);
    }

    return rows;
  }

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/capital/order/book/${symbol}`, true).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }
  }

  useEffect(() => {
    socket.emit('subscribe', { 
      symbol: symbol,
    });

    socket.on('orders', (result) => {
      setOrderList(result)
    });

    getData();
  }, []);

  return (
    <div className='requestData'>
      <div className='reqTitle'>
        <span>{t('buyRequest')}</span>
        <span>{t('sellRequest')}</span>
      </div>
      <div className='reqValues'>
        <span>{t('amount')}</span>
        <span>{t('price')}</span>
        <span>{t('amount')}</span>
      </div>
      <div className='requestTable'>
        <div className='requestTableSell'>{list(orderList[ETransaction.sell])}</div>
        <div className='requestTableBuy'>{list(orderList[ETransaction.buy])}</div>
      </div>
    </div>
  );
}

export default OrderBook;
