import { CircularProgress } from '@mui/material';
import Back from 'components/Icons/Back';
import config from 'config';
import { IMessage, IReply } from 'interfaces/chat';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';
import Close from './Close';
import Data from './Data';
import New from './New';

const Chat = () => {
  const { t } = useTranslation();
  const [chatList, setChatList] = useState<IMessage[]>([]);
  const [systemList, setSystemList] = useState<IReply[]>([]);
  const [loading, setLoading] = useState(true);
  const [isNewChat, setIsNewChat] = useState(false);
  const [isSystem, setIsSystem] = useState(true);
  const dispatch = useDispatch();

  const checkChat = async () => {
    setLoading(true);

    const result = await gather(`${config.api}/api/v1/live/support`, true).get();

    if (result.code === 200) {
      setChatList(result.data.messages);
      setIsSystem(false);
    } else {
      setIsNewChat(true);
    }
    
    setLoading(false);
  }

  useEffect(() => {
    checkChat();
  }, []);

  return (
    <div className='popup popupFix' id='system_chat'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('support')}</h2></span>
          <div className={`${(!isSystem ? 'closeChatIcon' : 'noneIcon')} notifIcon`} onClick={() => { !isSystem && dispatch(bottomDrawer(<Close />)) }}></div>
        </div>
      </div>
      {loading ? (
        <div className='loadingParent'>
          <CircularProgress color='info' size={30} />
        </div>
      ) : (
        isNewChat ? (
          <New setIsNewChat={setIsNewChat} setIsSystem={setIsSystem} setChatList={setChatList} setSystemList={setSystemList} />
        ) : (
          <Data setIsNewChat={setIsNewChat} isSystem={isSystem} setSystemList={setSystemList} chatList={chatList} setIsSystem={setIsSystem} setChatList={setChatList} systemList={systemList} />
        )
      )}
    </div>
  );
}

export default Chat;
