import OptionForm from 'components/Helper/OptionForm';
import config from 'config';
import { ETransaction } from 'enums/global';
import { EView } from 'enums/trade';
import useTicker from 'hooks/useTicker';
import { IBuySell } from 'interfaces/global';
import { IOrderBookProps } from 'interfaces/trade';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import { socket } from 'services/socket';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';

const manageDecimalData = (array: any[]) => {
  const map = new Map();
  array.forEach(e => {
    map.set(e, e)
  })
  return map;
}

const getDecimalPlaces = (value: number) => {
  const num = ([...value.toString().matchAll(/0.(0+)/g)].map(e => e[1])[0] ?? '').length;
  const go = (value.toString().split('.')[0] ?? '').length;
    
  let end = 4;
  let start = 0;
  if (value >= 50000) {
    end = 0;
  } else if (value >= 1000) {
    end = 1;
  } else if (value >= 100) {
    end = 2;
  } else if (value >= 1) {
    end = 3;
  }
  
  if (value >= 1000) {
    start = 3;
  } else if (value >= 100) {
    start = 2;
  } else if (value >= 10) {
    start = 1;
  }
  
  const arr = [];
    for (let i = 0; i < end; i++) {
    arr.push('0.' + '0'.repeat(num+i) + '1');
  }
  
  for (let i = 0; i < start; i++) {
    const x = Number('1'+'0'.repeat(go + i)) / 100000;
    if (x >= 1) {
      arr.push(x.toString());
    }
  }

  if (value >= 1) {
    arr.reverse()
  }
  
  return arr;
}

const OrderBook = ({ data, orderList, setOrderList }: IOrderBookProps) => {
  const { t } = useTranslation();
  const { price } = useTicker();
  const [view, setView] = useState(EView.both);
  const dispatch = useDispatch();
  const [decimal, setDecimal] = useState(1);
  const [decimalType, setDecimalType] = useState(new Map());

  const changeView = () => {
    if (view === EView.both) {
      setView(EView.buy);
    } else if (view === EView.buy) {
      setView(EView.sell);
    } else {
      setView(EView.both);
    }
  }
  
  const list = (data: IBuySell[], type: EView) => {
    const rows: React.ReactElement[] = [];
    const rounded: [number, number, number][] = [];
    
    data.forEach((value) => {
      let y = 0;
      if (decimal < 1) {
        const x = Number(decimal.toString().split('.')[1].length) || 0;
        y = Number(Number(value.price).toFixed(x));
      } else {
        y = Number(Math.round(value.price / decimal) * decimal);
      }

      if (rounded[y]) {
        rounded[y][1] += Number(value.amount);
        rounded[y][2] += Number(value.quantity);
      } else {
        rounded[y] = [Number(y), Number(value.amount), Number(value.amount)];
      }
    });

    const limit = (view === EView.both) ? 5 : 10;
    
    const newData = Object.values(rounded).slice(0, limit);

    const max = Math.max(...newData.map((e) => e[2]));
    const min = Math.min(...newData.map((e) => e[2]));
    
    if (type === EView.buy) {
      newData.reverse();
    }
    
    newData.forEach((value, i) => {
      let percent = ((value[2] - min) / (max - min)) * 100;
      if (percent < 1) {
        percent = 1;
      }

      rows.push(<span key={i}><b style={{ width: `${percent}%` }}></b><i>{autoFormatter(value[1],)}</i>{autoFormatter(value[0])}</span>)
    });

    for (let i = rows.length; i < limit; i++) {
      rows.push(<span key={1000 + i} className='noData'><b></b><i>0.0</i>0.0</span>)
    }

    return rows;
  }

  const orderBook = (result: any) => {
    setOrderList(result);
  }

  const getData = async () => {
    const result = await gather(`${config.api}/api/v1/capital/order/book/${data.symbol}`).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }
  }

  useEffect(() => {
    getData();
  }, [data]);

  useEffect(() => {
    const getPlace = manageDecimalData(getDecimalPlaces(price(data.symbol)));
    
    setDecimal(Array.from(getPlace.values()).pop());
    setDecimalType(getPlace);
  }, [data]);

  useEffect(() => {
    socket.emit('subscribe_orderBook', data.symbol);
    socket.on('orderBook', orderBook);

    return () => {
      socket.emit('unsubscribe_orderBook', data.symbol);
      socket.removeListener('orderBook', orderBook);
    }
  }, [data]);
  
  return (
    <div className='lastList'>
      <div className='tradeOption'>
        <div className={`changePrice changePrice_${view}`} onClick={() => changeView()}><span></span></div>
        <div className='select' onClick={() => dispatch(bottomDrawer(<OptionForm value={decimalType} def={decimal} setOption={setDecimal} />))}>
          <h6>{decimalType?.get(decimal)} decimal</h6>
        </div>
      </div>
      <div className='tradeTitle'>
        <span>{t('amount')}<br />({data.base})</span>
        <span>{t('price')}<br />({data.quote})</span>
      </div>
      <div className={`priceList priceList_${view}`}>
        <div className='requestTable requestTableSell'>
          <div>{list(orderList[ETransaction.sell], EView.sell)}</div>
        </div>
        <div className='average'>
          <b>{autoFormatter(price(data.symbol))}</b>
          <span>{data.quote}</span>
        </div>
        <div className='requestTable requestTableBuy'>
          <div>{list(orderList[ETransaction.buy], EView.buy)}</div>
        </div>
      </div>
    </div>
  );
}

export default OrderBook;
