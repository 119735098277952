export enum ETab {
  star = 'star',
  all = 'all',
  currencyUnit = 'currencyUnit',
}

export enum ESort {
  value = 'value',
  price = 'price',
  change = 'change',
}

export enum EOrder {
  asc = 'asc',
  desc = 'desc',
}
