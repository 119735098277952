import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const Convert = ({ value }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <b><i>{t('from')}</i> {autoFormatter(value.amount)} {value.from}</b>
      <b><i>{t('to')}</i> {autoFormatter(value.receive)} {value.to}</b>
    </div>
  );
}

export default Convert;
