export enum ETab {
  request = 'request',
  info = 'info',
}

export enum ESection {
  deep = 'deep',
  trades = 'trades',
}

export enum EChart {
  line = 'line',
  min1 = 'min1',
  min5 = 'min5',
  min15 = 'min15',
  hour1 = 'hour1',
  day1 = 'day1',
  week1 = 'week1',
}
