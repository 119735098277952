import { CircularProgress } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import Close from 'components/Icons/Close';
import Convert from 'components/Modules/Convert';
import Receive from 'components/Modules/Receive';
import Send from 'components/Modules/Send';
import config from 'config';
import { ETransaction } from 'enums/global';
import { ETab } from 'enums/wallet';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IWalletProps } from 'interfaces/wallet';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import Data from './Data';

const Wallet = ({ symbol }: IWalletProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { price } = useTicker();
  const { coins, currencyUnit } = useGlobal();
  const { balance } = useUser();
  const [tab, setTab] = useState(ETab.buySell);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([])

  const tabTitle = {
    [ETab.buySell]: t('buyAndSell'),
    [ETab.sendReceive]: t('sendAndReceive'),
    [ETab.convert]: t('change'),
  }

  const userBalance = balance.wallet[symbol];
  const data = coins[symbol];

  const open = (content: any) => {
    dispatch(hideLoading())
    setTimeout(() => {
      dispatch(fullDrawer(content))
    }, 250)
  }

  const getData = async (address: string, type?: ETransaction) => {
    const result = await gather(`${config.api}/api/v1/${address}?coin=${symbol}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) => Object.assign({ tab: tab, [type ? 'type' : '']: type }, e));
    }
    return [];
  }

  const getList = async () => {
    setLoading(true);
    setList([]);
    
    const result = await Promise.all([
      getData('capital/send', ETransaction.send),
      getData('capital/receive', ETransaction.receive),
      getData('capital/convert', ETransaction.convert),
      getData('capital/order'),
    ]).then((results) => {
      return [].concat.apply([], [results[0], results[1], results[2], results[3]]);
    });

    setLoading(false);
    setList(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className='popup popupDown'>
      <Close />
      <div className='headDetail'>
        <img src={data.icon} alt='' />
        <h2>{data.symbol}</h2>
        <h6>{autoFormatter(userBalance.free)}</h6>
        <p>≈ {autoFormatter(price(`${data.symbol}${currencyUnit}`, 1) * userBalance.free)} {currencyUnit}</p>
        <p>≈ {autoFormatter(price(`${data.symbol}USDT`, 1) * userBalance.free)} USDT</p>
      </div>
      <div className='detailTab'>
        <h1>{t('history')}</h1>
        <div>
          <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
        </div>
      </div>
      <div className='bodyDetail'>
        { loading ? (
          <div className='loadingParent'><CircularProgress size={20} /></div>
        ) : (
          <Data list={list} tab={tab} />
        )}
      </div>
      <section className='parentShare parentDetail'>
        <div className='share' onClick={() => open(<Send value={data.symbol} />) }>{t('send')}</div>
        <div className='share' onClick={() => open(<Receive value={data.symbol} />) }>{t('receive')}</div>
        <div className='share' onClick={() => open(<Convert value={data.symbol} />) }>{t('change')}</div>
      </section>
    </div>
  )
}

export default Wallet;
