import { ETab } from 'enums/bill';
import { EStatus, ETransaction } from 'enums/global';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer } from 'services/reducer/drawer';
import { cardColor, statusColor } from 'utils/color';
import { formatter } from 'utils/numeral';
import Detail from './Detail';
import BuySell from './card/BuySell';
import Convert from './card/Convert';
import DepositWithdraw from './card/DepositWithdraw';
import SendReceive from './card/SendReceive';

const Data = ({ list, tab, filter }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const findCard = (value: any) => {
    switch (value.type) {
      case ETransaction.deposit:
      case ETransaction.withdraw:
        return <DepositWithdraw value={value} />;

      case ETransaction.buy:
      case ETransaction.sell:
        return <BuySell value={value} />;

      case ETransaction.receive:
      case ETransaction.send:
        return <SendReceive value={value} />;
        
      default:
        return <Convert value={value} />;
    }
  }

  const show = () => {
    var rows: React.ReactElement[] = [];

    if (tab !== ETab.all) {
      list = list.filter((e: any) => e.tab === tab)
    }

    if (filter) {
      list = list.filter((e: any) => e.type === filter || e.status === filter)
    }

    if(list.length > 0) {
      list.forEach((value: any, key: number) => {
        rows.push(
          <span onClick={() => dispatch(bottomDrawer((<Detail value={value} />)))} className={`chargeData block${cardColor(value.type, value.status)} trx_${value.type}`} key={key}>
            <div>
              <i></i>
              {value.tab === ETab.trade && value.status === EStatus.cancel && (
                <span className='viaCanceled'>{t('canceled')} ({formatter(value.progress, 2)}%)</span>
              )}
              <h3>
                <b className={`color${cardColor(value.type, value.status)}`}>
                  {t(value.type)} {value.type === ETransaction.withdraw && t('fromDex')}
                </b>
                {value.status === EStatus.reject && (
                  <em className={'color'+statusColor(value.status)}>({t('unsuccess')})</em>
                )}
                {value.tab === ETab.trade && value.pair}
              </h3>
              <p>{moment(value.createdAt).locale('fa').format('DD MMM , HH:mm')}</p>
            </div>
            {findCard(value)}
          </span>
        )
      });
    } else {
      rows.push(<span className='notifData notFound' key={1}>{t('notFound')}</span>);
    }

    return rows;
  }

  return (
    <div className='notifList transList'>
      {show()}
    </div>
  );
}

export default Data;
