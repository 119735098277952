import { ICode } from 'interfaces/helper';
import { isNumber } from 'lodash';
import { memo } from 'react';

const PatchCode = ({ code, setCode }: ICode) => {
  const selectText = (e: any) => {
    e.target.select();
  }

  const setCodeData = (e: any) => {
    const value = e.target.value.slice(0, 1);
    
    setCode((prevCode) => {
      const newValue = [...prevCode];
      newValue[e] = value;
      return newValue;
    });

    const form = e.target.form;
    const index = [...form].indexOf(e.target);
    if (index < 5 && value !== '' && e.target.name !== 5) {
      form.elements[index + 1].focus();
      form.elements[index + 1].select();
    }
  }

  const checkCode = (e:any) => {
    if (!isNumber(e.key)) {
      e.target.value = '';
    }
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    for (let i = 0; i <= 5; i++) {
      rows.push(<input key={i} value={code[i]} name={i.toString()} onKeyUp={(e) => checkCode(e)} onChange={(e) => setCodeData(e)} onClick={(e) => selectText(e)} type='number' />);
    }
    
    return rows;
  }
 
  return (
    <form className='verifyCode'>
      {list()}
    </form>
  );
}

export default memo(PatchCode);
