import OpenLogin from 'components/Layouts/OpenLogin';
import Chat from 'components/Modules/Chat';
import { useDispatch } from 'react-redux';
import { bottomDrawer, fullDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';

const Support = () => {
  const dispatch = useDispatch();

  return (<div className='headIcon support' onClick={() => isUserLogin() ? dispatch(fullDrawer(<Chat />)) : dispatch(bottomDrawer(<OpenLogin />))}></div>);
}

export default Support;
