import { CircularProgress } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import config from 'config';
import { EChart } from 'enums/currency';
import { IChartProps, ILine, IStock } from 'interfaces/currency';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import gather from 'utils/gather';
import { getRandomNumber } from 'utils/numeral';
import View from './View';

const Chart = ({ symbol, randomCandle, setRandomCandle }: IChartProps) => {
  const [searchParams] = useSearchParams();
  const [line, setLine] = useState<ILine[]>([]);
  const [stock, setStock] = useState<IStock[]>([]);
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState(EChart.min15);

  const tabTitle = {
    [EChart.line]: 'Line',
    [EChart.min1]: '1m',
    [EChart.min5]: '5m',
    [EChart.min15]: '15m',
    [EChart.hour1]: '1h',
    [EChart.day1]: '1d',
    [EChart.week1]: '1w',
  }

  const getChart = async () => {
    setLoading(true);

    const result = await gather(`${config.api}/api/v1/live/chart/${symbol}?from=${Math.floor(moment().subtract(150, 'd').valueOf())}&to=${Math.floor(moment().add(10, 'm').valueOf())}&type=${period}`, true).get();
    if (result.code === 200) {
      const dataLine: ILine[] = [];
      const dataStock: IStock[] = [];

      for (const element of result.data.slice(0, 30)) {
        dataLine.push({
          x: new Date(element.time * 1000),
          y: Number(element.close),
        });

        dataStock.push({
          label: moment.unix(element.time).format(['min5', 'min15', 'hour1'].includes(period) ? 'HH:mm' : 'MM-DD'),
          y: [Number(element.open), Number(element.high), Number(element.low), Number(element.close)],
          color: element.open < element.close ? '#38B781' : '#FF5151',
        });
      }

      dataLine.reverse();
      dataStock.reverse();

      setLine(dataLine);
      setStock(dataStock);
    }

    setLoading(false);

    const ai = searchParams.get('ai')!;
    if (ai) {
      setRandomCandle(true);
    }
  }

  useEffect(() => {
    setRandomCandle(false);
    if (period !== EChart.line) {
      getChart();
    }
  }, [period]);

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    
    const element = document.querySelector('.tabChart') as any;
    if (element) {
      if (expand) {
        element.style.width = h+'px';
        element.style.height = w+'px';
        element.style.top = h+'px';
      } else {
        element.style.width = ''
        element.style.height = ''
      }
    }
  }, [expand]);

  useEffect(() => {
    if (randomCandle) {
      const dataStock: IStock[] = JSON.parse(JSON.stringify(stock));

      for (let i = 0; i < 3; i++) {
        const getLastData = JSON.parse(JSON.stringify(stock)).pop();
      
        let lim = 0.005;
        if (period === EChart.day1 || period === EChart.week1) {
          lim = 0.05;
        } else if (period === EChart.min5 || period === EChart.min15) {
          lim = 0.0005;
        }

        const open = Number(getLastData?.y[3]);
        const high = open + open * getRandomNumber(0, lim, 6);
        const low = open + open * getRandomNumber(-lim, 0, 6);
        const close = low + low * getRandomNumber(lim, 0, 6);

        dataStock.push({
          label: getLastData?.label!,
          y: [open, high, low, close],
          color: '#ffe710',
        });
      }
      
      setStock(dataStock);
    } else {
      setStock(JSON.parse(JSON.stringify(stock)).slice(0, -3));
    }
  }, [randomCandle]);

  return (
    <div className={`tabChart tab_${expand}`}>
      <div className='tab'>
        <Tabs titles={tabTitle} selected={period} setSelected={setPeriod} />
        <div className='expand' onClick={() => setExpand(!expand)}></div>
      </div>
      {loading ? (
        <div className='loadingParent'><CircularProgress size={20} /></div>
      ) : (
        <View line={line} stock={stock} period={period} />
      )}
    </div>
  );
}

export default memo(Chart);
