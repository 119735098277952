import Back from 'components/Icons/Back';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Verify from './Verify';

const Withdraw = ({ value }: { value: string}) => {
  const { t } = useTranslation();
  const [isForm, setIsForm] = useState(true);
  const [id, setId] = useState('');

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('withdraw')} {t('fromDex')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
        {isForm ? (
          <Form symbol={value} setIsForm={setIsForm} setId={setId} />
        ) : (
          <Verify id={id} />
        )}
      </div>
    </div>
  )
}

export default Withdraw;
