import { ETransaction } from 'enums/global';
import { ETab } from 'enums/wallet';
import { IDataProps, IList } from 'interfaces/wallet';
import { useTranslation } from 'react-i18next';
import { statusColor } from 'utils/color';
import BuySell from './card/BuySell';
import Convert from './card/Convert';
import DepositWithdraw from './card/DepositWithdraw';
import SendReceive from './card/SendReceive';

const Data = ({ list, tab }: IDataProps) => {
  const { t } = useTranslation();
  
  const findCard = (value: IList) => {
    switch (value.type) {
      case ETransaction.buy:
      case ETransaction.sell:
        return <BuySell value={value} />;

      case ETransaction.receive:
      case ETransaction.send:
        return <SendReceive value={value} />;

      case ETransaction.deposit:
      case ETransaction.withdraw:
        return <DepositWithdraw value={value} />;
        
      default:
        return <Convert value={value} />;
    }
  }

  const showData = () => {
    var rows: React.ReactElement[] = [];

    if (tab === ETab.buySell) {
      list = list.filter((e: any) => (e.type === ETransaction.buy || e.type === ETransaction.sell));
    } else if (tab === ETab.sendReceive) {
      list = list.filter((e: any) => e.type === ETransaction.send || e.type === ETransaction.receive);
    } else if (tab === ETab.deposit) {
      list = list.filter((e: any) => e.type === ETransaction.deposit);
    } else if (tab === ETab.withdraw) {
      list = list.filter((e: any) => e.type === ETransaction.withdraw);
    } else {
      list = list.filter((e: any) => e.type === ETransaction.convert);
    }

    if (list.length > 0) {
      list.forEach((value, key) => {
        rows.push(
          <span className={`chargeData block${statusColor(value.status)}`} key={key}>
            {findCard(value)}
          </span>
        )
      });
    } else {
      rows.push(<span className='notifData notFound' key={1}>{t('notFound')}</span>);
    }

    return rows;
  }

  return (
    <>
      {showData()}
    </>
  );
}

export default Data;
