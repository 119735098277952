import Tabs from 'components/Helper/Tabs';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { ICoinList } from 'interfaces/layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import { priceColor } from 'utils/color';
import { formatter } from 'utils/numeral';
import SearchBox from './SearchBox';

const PairList = ({ setSelect }: ICoinList) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { price, change } = useTicker();
  const { pairs, coins } = useGlobal();
  const [tab, setTab] = useState('all');
  const [search, setSearch] = useState('');

  const tabTitle = {
    star: <i className='star'></i>, 
    all: t('all'),
  }

  const open = (value: string) => {
    dispatch(hideDrawer());
    setSelect(value);
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    const find = Object.values(pairs).filter(e => e.name.toLowerCase().includes(search.toLowerCase()) || e.symbol.toLowerCase().includes(search.toLowerCase()));
    if (find.length > 0) {
      find.forEach((element, i) => {
        const coin = coins[element.base];
        const percent = change(element.symbol);
        const hue = priceColor(percent);

        rows.push(
          <div key={i} className='currencyList currencyListMenu' onClick={() => open(element.symbol)}>
            <span>{formatter(price(element.symbol))}<b className={`color${hue}`}>{formatter((percent), 2, 2)}%</b></span>
            <span>
              <img src={coin.icon} alt='' /> 
              {element.base}/{element.quote}
            </span>
          </div>
        );
      });
    } else {
      rows.push(<div className='notFound' key={1}>{t('notFound')}</div>);
    }

    return rows;
  }

  return (
    <div className='popup popupCurrency'>
      <div className='header headerPopup'>
        <SearchBox setSearch={setSearch} />
      </div>
      <div className='notifList transList transList_trade'>
        <div className='currencyTitle'>
          <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
        </div>
        {list()}
      </div>
    </div>
  );
}

export default PairList;
