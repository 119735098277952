import OpenLogin from 'components/Layouts/OpenLogin';
import config from 'config';
import { IDirectProps } from 'interfaces/trade';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { bottomDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const Bot = ({ symbol, data }: IDirectProps) => {
  const { t } = useTranslation();
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  const start = async () => {
    if (!isUserLogin()) {
      dispatch(bottomDrawer(<OpenLogin />));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/order/bot`, true).post({
      symbol: symbol,
      buyPrice: buy,
      sellPrice: sell,
      quantity: value,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setBuy(0);
      setSell(0);
      setValue(0);
      dispatch(successAlert(t('orderSuccessful')));
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  return (
    <>
      <h2 className='botTitle'>{t('buySellBot')}</h2>
      <div className='box'>
        <span></span>
        <input type='text' value={formatter(buy)} onChange={(e) => setBuy(Number(e.target.value))} placeholder={`${data.quote} ${t('buyPriceTo')}`} />
        <span></span>
      </div>
      <div className='box'>
        <span></span>
        <input type='text' value={formatter(sell)} onChange={(e) => setSell(Number(e.target.value))} placeholder={`${data.quote} ${t('sellPriceTo')}`} />
        <span></span>
      </div>
      <div className='box'>
        <span></span>
        <input type='text' value={formatter(value)} onChange={(e) => setValue(Number(e.target.value))} placeholder={`${data.quote} ${t('amountTo')}`} />
        <span></span>
      </div>
      <div className='almost'>Buy ≈ {formatter(Number(value) * Number(buy))} {(data.quote)}</div>
      <div className='almost'>Sell ≈ {formatter(Number(value) * Number(sell))} {(data.quote)}</div>
      <div className='almost'>Profit ≈ {formatter(Number(value) * Number(sell) - Number(value) * Number(buy))} {(data.quote)}</div>
      <div className='buyAndSell buyAndSellTrade'>
        <span onClick={() => start()}>{t('tradeNow')}</span>
      </div>
    </>
  )
}

export default Bot;
