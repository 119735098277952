import OpenLogin from 'components/Layouts/OpenLogin';
import Notif from 'components/Modules/Notif';
import { useDispatch } from 'react-redux';
import { bottomDrawer, fullDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';

const Alarm = () => {
  const dispatch = useDispatch();

  return (<div className='headIcon' onClick={() => isUserLogin() ? dispatch(fullDrawer(<Notif />)) : dispatch(bottomDrawer(<OpenLogin />))}></div>);
}

export default Alarm;
