import { EType } from 'enums/chat';
import { IData, IMessage } from 'interfaces/chat';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { socket } from 'services/socket';
import System from './System';

const Data = ({ chatList, systemList, setChatList, isSystem, setSystemList, setIsSystem, setIsNewChat }: IData) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [reply, setReply] = useState('');

  const addToMessage = (data: IMessage) => {
    setChatList(chatList => [...chatList, data]);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 10);
  }

  const socketChat = (result: IMessage) => {
    addToMessage({
      isOperator: result.isOperator,
      value: result.value,
      type: result.type,
    });
  }

  const send = async () => {
    if (reply.trim()) {
      if (isSystem) {
        setIsSystem(false);

        addToMessage({
          isOperator: true,
          type: EType.system,
          value: 'moveToSupport',
        });
      }

      socket.emit('message', {
        value: reply,
        type: EType.text,
      });
    }

    setReply('');
  }

  const showData = () => {
    const rows: React.ReactElement[] = [];
    
    if (chatList.length > 0) {
      chatList.forEach((value, i) => {
        if (value.type === EType.system) {
          rows.push(
            <div className={`message_${value.isOperator}_${value.value}`} key={i}>
              <i>{t(value.value)}</i>
            </div>
          );
        } else {
          rows.push(
            <span className={`message_${value.isOperator}`} id={`code_${value.code}`} key={i}>
              <section>
                {value.value}
                {value.code && (<i className='loadingMessage'></i>)}
              </section>
            </span>
          );
        }
      });
    }

    return rows;
  }

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 10);
    
    socket.on('message', socketChat);
    return () => {
      socket.removeListener('message', socketChat);
    }
  }, []);
  
  return (
    <div className='showData'>
      <div className='dataShare'>
        <div className='messageList'>
          {showData()}
          <div ref={ref} />
        </div>
      </div>
      {isSystem && (
        <System systemList={systemList} setSystemList={setSystemList} setIsNewChat={setIsNewChat} />
      )}
      <div className='sendReply'>
        <span className={reply === '' ? 'notReady' : ''} onClick={() => send()}></span>
        <input type='text' onChange={(e) => setReply(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && send()} value={reply} placeholder={`${t('textHere')}`} />
        <i></i>
      </div>
    </div>
  );
}

export default Data;
