import Bill from 'components/Modules/Bill';
import Convert from 'components/Modules/Convert';
import Deposit from 'components/Modules/Deposit';
import Receive from 'components/Modules/Receive';
import Send from 'components/Modules/Send';
import Withdraw from 'components/Modules/Withdraw';
import useGlobal from 'hooks/useGlobal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer, fullDrawer, leftDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';
import CoinList from './CoinList';
import OpenLogin from './OpenLogin';

const Shortcuts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencyUnit } = useGlobal();
  const [type, setType] = useState('');
  const [select, setSelect] = useState('');
  
  const open = (content: any, type: string = '') => {
    setType(type);

    if (!isUserLogin()) {
      dispatch(bottomDrawer(<OpenLogin />));
      return;
    }
    
    dispatch(content);
  }

  useEffect(() => {
    if (select) {
      setSelect('');
      
      setTimeout(() => {
        if (type === 'send') {
          dispatch(fullDrawer(<Send value={select} />));
        } else if (type === 'receive') {
          dispatch(fullDrawer(<Receive value={select} />));
        } else if (type === 'convert') {
          dispatch(fullDrawer(<Convert value={select} />));
        } else {
          //
        }
      }, 250);
    }
  }, [select]);

  return (
    <section className='shortcut'>
      <div onClick={() => open(!isUserLogin() ? dispatch(bottomDrawer(<OpenLogin />)) : fullDrawer(<Bill />))}>
        <span></span>
        <h3>{t('bill')}</h3>
      </div>
      <div onClick={() => open(leftDrawer(<CoinList setSelect={setSelect} />), 'send')}>
        <span></span>
        <h3>{t('send')}</h3>
      </div>
      <div onClick={() => open(leftDrawer(<CoinList setSelect={setSelect} />), 'receive')}>
        <span></span>
        <h3>{t('receive')}</h3>
      </div>
      <div onClick={() => open(leftDrawer(<CoinList setSelect={setSelect} />), 'convert')}>
        <span></span>
        <h3>{t('exchange')}</h3>
      </div>
      <div onClick={() => open(fullDrawer(<Withdraw value={currencyUnit} />))}>
        <span></span>
        <h3>{t('withdraw')}</h3>
      </div>
      <div onClick={() => open(fullDrawer(<Deposit />))}>
        <span></span>
        <h3>{t('chargeAccount')}</h3>
      </div>
    </section>
  );
}

export default Shortcuts;
