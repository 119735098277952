import Back from 'components/Icons/Back';
import CoinList from 'components/Layouts/CoinList';
import useGlobal from 'hooks/useGlobal';
import { ISendProps } from 'interfaces/send';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { leftDrawer } from 'services/reducer/drawer';
import Form from './Form';
import Verify from './Verify';

const Send = ({ value }: ISendProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { coins } = useGlobal();
  const [symbol, setSymbol] = useState(value)
  const [isForm, setIsForm] = useState(true);
  const [id, setId] = useState('');
  
  const data = coins[symbol];

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('send')}</h2></span>
          <div className='notifIcon noneIcon'></div>
        </div>
      </div>
      <div className='tradeForm'>
        <div className='box boxMarket boxReceive' onClick={() => dispatch(leftDrawer(<CoinList setSelect={setSymbol} />))}>
          <img src={data.icon} alt='' />
          {data.name}
        </div>
        {isForm ? (
          <Form data={data} setId={setId} setIsForm={setIsForm} />
        ) : (
          <Verify id={id} />
        )}
      </div>
    </div>
  )
}

export default Send;
