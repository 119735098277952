import { ICardProps } from 'interfaces/wallet';
import moment from 'jalali-moment';
import { formatter } from 'utils/numeral';

const DepositWithdraw = ({ value }: ICardProps) => {
  return (
    <>
      <div className='smaller'>
        <h3><b>{formatter(value.amount)}</b> {value.currency}</h3>
        {value.provider && <p>{value.provider}</p>}
      </div>
      <div>
        <b>{value.card.number.match(new RegExp('.{1,4}', 'g'))?.join(' ')}</b>
        <b><i>{moment(value.createdAt).locale('fa').format('DD MMM , HH:mm')}</i></b>
      </div>
    </>
  )
}

export default DepositWithdraw;
