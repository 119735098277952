import OpenLogin from 'components/Layouts/OpenLogin';
import UserMenu from 'components/Modules/UserMenu';
import { useDispatch } from 'react-redux';
import { bottomDrawer, leftDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';

const Profile = () => {
  const dispatch = useDispatch();

  return (<div className='profile' onClick={() => isUserLogin() ? dispatch(leftDrawer(<UserMenu />)) : dispatch(bottomDrawer(<OpenLogin />))}></div>);
}

export default Profile;
