import { EStatus, ETransaction } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const BuySell = ({ value }: any) => {
  const { t } = useTranslation();
  const { pairs } = useGlobal();

  const data = pairs[value.pair];

  return (
    <>
      <span>
        <i>{t('type')}</i>
        <b>{t(value.type)}</b>
      </span>
      <span>
        <i>{t('singleValue')}</i>
        <b>{autoFormatter(value.price)} {data.quote}</b>
      </span>
      <span>
        <i>{t('sendAmount')}</i>
        <b>{autoFormatter(value.amount)} {data.base}</b>
      </span>
      <span>
        <i>{t('fee')}</i>
        <b>{autoFormatter(value.fee)} {value.type === ETransaction.buy ? data.base : data.quote}</b>
      </span>
      {value.status === EStatus.cancel && (
        <span>
          <i>{t('progressed')}</i>
          <b>{autoFormatter(value.progress)}%</b>
        </span>
      )}
      <span>
        <i>{t('orderAmount')}</i>
        <b>{autoFormatter(value.total)} {data.quote}</b>
      </span>
    </>
  );
}

export default BuySell;
