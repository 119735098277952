import Tabs from 'components/Helper/Tabs';
import { EPairModel } from 'enums/global';
import { EOrder, ESort, ETab } from 'enums/market';
import useFavorite from 'hooks/useFavorite';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { IMainListProps } from 'interfaces/market';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { priceColor } from 'utils/color';
import { autoFormatter, formatter } from 'utils/numeral';

const MainList = ({ search }: IMainListProps) => {
  const { t } = useTranslation();
  const { favorites } = useFavorite();
  const { price, change, volume } = useTicker();
  const { pairs } = useGlobal();
  const [sort, setSort] = useState(ESort.change);
  const [order, setOrder] = useState(EOrder.desc);
  const [tab, setTab] = useState(ETab.all);
  
  const extraTab = Object.values(pairs)
    .filter(e => e.model === EPairModel.coin)
    .map(e => e.quote)
    .filter((value, index, array) => array.indexOf(value) === index)
    .reduce((ac, a) => ({ ...ac, [a]: a }), {});

  const tabTitle = { 
    [ETab.star]: <i className='star'></i>,
    [ETab.all]: t('all'), 
    ...extraTab,
    [ETab.currencyUnit]: t('currencyUnit'),
  };

  const sortTitle = {
    [ESort.value]: t('marketValue'), 
    [ESort.price]: t('price'), 
    [ESort.change]: t('24hour'),
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    let data = Object.values(pairs);
    if (tab !== ETab.all) {
      if (tab === ETab.star) {
        data = data.filter(e => favorites.includes(e.symbol));
      } else {
        if (tab === ETab.currencyUnit) {
          data = data.filter(e => e.model === EPairModel.currency);
        } else {
          data = data.filter(e => e.quote.endsWith(tab));
        }
      }
    }

    // data = data.sort((a, b) => [sort](b.symbol) - [sort](a.symbol));
    if (order === EOrder.asc) {
      data.reverse();
    }

    if (search) {
      data = data.filter(e => e.symbol.toLowerCase().includes(search.toLowerCase()) || e.symbol.toLowerCase().includes(search.toLowerCase()));
    }

    data.forEach((element) => {
      const percent = change(element.symbol);
      const hue = priceColor(percent);

      rows.push(
        <Link to={`/currency/?symbol=${element.symbol}`} className='currencyList' key={element.symbol}>
          <span><i className={`bg${hue}Percent color${hue}`}>{formatter(percent, 2, 2)}%</i></span>
          <span>{autoFormatter(price(element.symbol))}<b>{element.quote}</b></span>
          <span>{element.base}/<small>{element.quote}</small><b><i>${autoFormatter(volume(element.symbol))}</i> {t('value')}</b></span>
        </Link>
      );
    })

    if (rows.length <= 0) {
      rows.push(<div className='notFound' key={1}>{t('notFound')}</div>);
    }

    return rows;
  }

  const changeSort = (value: ESort) => {
    if (sort === value) {
      if (order === EOrder.desc) {
        setOrder(EOrder.asc);
      } else {
        setOrder(EOrder.desc);
      }
    } else {
      setSort(value);
      setOrder(EOrder.desc);
    }
  }

  const selectSort = () => {
    const rows: React.ReactElement[] = [];

    Object.entries(sortTitle).forEach(([key, value], i) => {
      rows.push(<span key={i} className={key === sort ? `active ${order}` : ''} onClick={() => changeSort(key as ESort)}>{value}<i></i></span>);
    });

    return rows;
  }

  return (
    <>
      <div className='currencyTitle currencyTitleMarket'>
        <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
      </div>
      <div className='main'>
        <section className='currency currencyMarket'>
          <div className='sortTable'>
            {selectSort()}
          </div>
          {list()}
        </section>
      </div>
    </>
  )
}

export default MainList;
