import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Online = ({ amount, card }: { amount: number, card: string}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencyUnit } = useGlobal();

  const start = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.api}/api/v1/account/deposit`, true).post({
      card: card,
      amount: amount,
      currency : currencyUnit,
      provider: 'zarinpal',
      callback: `${config.pwa}/payment`,
    });

    if (result.code === 200) {
      dispatch(successAlert(t('connectingToBank')));
      window.location.href = result.data.link;
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }

    dispatch(hideLoading());
  }
  
  return (
    <>
      <div className='gatewyLogo'>
        {t('gatewayPayment')} : <span className='zarinpal'></span>
      </div>
      <div className='showData'>
        <div className='dataShare'></div>
        <div className='share' onClick={() => start()}>{t('goToGateway')}</div>
      </div>
    </>
  )
}

export default Online;
