import config from 'config';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Rate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);
  const [text, setText] = useState('')

  const send = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.api}/api/v1/live/support`, true).put({
      rate: rate,
      comment: text,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(t(result?.message) || t('errorFound')));
    }
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    for (let i = 1; i <= 5; i++) {
      rows.push(<span key={i} className={i === rate ? 'active' : ''} onClick={() => setRate(i)}></span>);
    }
    
    return rows;
  }

  return (
    <div className='popup popupDown'>
      <div className='confirmTitle'>{t('howReply')}</div>
      <div className='rate'>{list()}</div>
      <div className='confirmText'>{t('howReplyDetail')}</div>
      <form className='ticketForm'>
        <textarea onChange={(e) => setText(e.target.value)}></textarea>
      </form>
      <div className='parentDate'>
        <div className='share' onClick={() => send()}>{t('submit')}</div>
      </div>
    </div>
  )
}

export default memo(Rate);
