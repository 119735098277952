import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { ITicker } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  tickers: {} as { [k: string]: ITicker },
}

const getTickers = createAsyncThunk('price/fetchPrice', async () => {
  return await gather(config.api + '/api/v1/live/ticker').get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateTicker: (state, action) => {
      state.tickers[action.payload.pair] = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickers.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ITicker) => {
            state.tickers[value.pair] = value;
          });
        }
      })
  },
});

export {
  getTickers
};

export const { 
  updateTicker,
} = slice.actions;

export default slice.reducer;
