import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './files/ar.json';
import en from './files/en.json';
import fa from './files/fa.json';
import fr from './files/fr.json';

const resources = {
  en: {
    translation: en,
  },
  fa: {
    translation: fa,
  },
  ar: {
    translation: ar,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fa',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
