import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { ICoin, ICurrency, ILevel, IPair, ISetting, IState, ISubject } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  subjects: [] as ISubject[],
  states: [] as IState[],
  setting: {} as ISetting,
  pairs: {} as { [k: string]: IPair },
  levels: {} as { [k: string]: ILevel },
  currencyUnit: 'TMN',
  currencies: {} as { [k: string]: ICurrency },
  coins: {} as { [k: string]: ICoin },
}

const getPairs = createAsyncThunk('pairs/fetchPairs', async () => {
  return await gather(`${config.api}/api/v1/market/pair`).get();
});

const getCoins = createAsyncThunk('coins/fetchCoins', async () => {
  return await gather(`${config.api}/api/v1/market/coin`).get();
});

const getCurrencies = createAsyncThunk('currencies/fetchCurrencies', async () => {
  return await gather(`${config.api}/api/v1/market/currency`).get();
});

const getSetting = createAsyncThunk('setting/fetchSetting', async () => {
  return await gather(`${config.api}/api/v1/data/setting`).get();
});

const getLevels = createAsyncThunk('levels/fetchLevels', async () => {
  return await gather(`${config.api}/api/v1/data/level`).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeExUnit: (state, action) => {
      state.currencyUnit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSetting.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.subjects = action.payload.data.subjects;
          state.states = action.payload.data.states;
        }
      })
      .addCase(getCoins.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ICoin) => {
            state.coins[value.symbol] = value;
            state.coins[value.symbol].icon = `${config.api}/icons/${value.symbol.toLowerCase()}.png`;
          });
        }
      })
      .addCase(getPairs.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.pairs[value.symbol] = value
          })
        }
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ICurrency) => {
            state.currencies[value.symbol] = value;
            state.currencies[value.symbol].icon = `${config.api}/icons/${value.symbol.toLowerCase()}.png`;
          });
        }
      })
      .addCase(getLevels.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ILevel) => {
            state.levels[value.type] = value;
          })
        }
      })
  },
});

export {
  getCoins,
  getCurrencies,
  getLevels,
  getPairs,
  getSetting
};

export const {
  changeExUnit,
} = slice.actions;

export default slice.reducer;
