import Back from 'components/Icons/Back';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import NewCard from './NewCard';

const CardList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cards } = useUser();
  
  const list = () => {
    const rows: React.ReactElement[] = [];
    
    if (Object.values(cards).length > 0) {
      Object.values(cards).forEach((value, i) => {
        rows.push(
          <div key={i} className={!value.bank ? 'noBank' : ''}>
            {value.bank && <img src={value.icon} alt='' /> }
            <span>
            {value.bank && <img src={value.icon} alt='' /> }
              <b>{value.number.match(new RegExp('.{1,4}', 'g'))?.join('-')}</b>
              <i>{value?.iban ?? '-----------'}</i>
            </span>
          </div>
        )
      });
    } else {
      rows.push(<span className='notifData notFound' key={1}>{t('notFound')}</span>);
    }

    return rows;
  }

  return (
    <div className='popup popupFix'>
      <div className='header headerPopup'>
        <div className='info'>
          <Back />
          <span><h2>{t('bankInfo')}</h2></span>
          <div className='notifIcon addCard' onClick={() => dispatch(fullDrawer(<NewCard />))}></div>
        </div>
      </div>
      <div className='allUserCard'>
        {list()}
      </div>
    </div>
  )
}

export default CardList;
