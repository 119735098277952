import { ETransaction } from 'enums/global';
import { useTranslation } from 'react-i18next';
import { statusColor } from 'utils/color';
import { autoFormatter } from 'utils/numeral';

const DepositWithdraw = ({ value }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <b><i>{value.type === ETransaction.deposit ? t('amount') : t('payAmount')}</i> {autoFormatter(value.amount)}</b>
      <b><i>{t('status')}</i> <em className={`color${statusColor(value.status)}`}>{t(value.status)}</em></b>
    </div>
  );
}

export default DepositWithdraw;
