import CanvasJSReactLine from 'assets/js/canvasjs.react';
import CanvasJSReactStock from 'assets/js/canvasjs.stock.react';
import { EChart } from 'enums/currency';
import { IViewProps } from 'interfaces/currency';

const View = ({ period, stock, line }: IViewProps) => {
  const CanvasJSChart = CanvasJSReactLine.CanvasJSChart;
  const CanvasJSStockChart = CanvasJSReactStock.CanvasJSStockChart;

  const lineOption = {
    theme: 'light2',
    animationEnabled: false,
    exportEnabled: false,
    backgroundColor: '#171A1F',
    height: 250,
    axisY2:{
      gridThickness: 1,
      gridColor: '#262a2d',
      lineThickness: 0,
      tickLength: 0,
      labelFontSize: 10,
      labelFontColor: '#99A6AF',
      margin: -2
    },
    axisX:{
      valueFormatString: ['5m', '15m', '1h'].includes(period) ? 'HH:mm' : 'MM-DD',
      title: 'D',
      titleFontSize: 2,
      titleFontColor: '#171A1F',
      gridThickness: 0,
      lineThickness: 0,
      tickLength: 0,
      labelFontSize: 10,
      labelFontColor: '#99A6AF'
    },
    toolTip:{
      enabled: false
    },
    data: [{
      type: 'area',
      lineColor: '#4598D3',
      color: '#4598D3',
      markerType: 'none',
      axisYType: 'secondary',
      dataPoints: line,
    }]
  }

  const stockOption = {
    theme: 'light2',
    backgroundColor: '#171A1F',
    height: 250,
    exportEnabled: false,
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      height: 1
    },
    charts: [{
      axisY2:{
        gridThickness: 1,
        gridColor: '#262a2d',
        lineThickness: 0,
        tickLength: 0,
        labelFontSize: 10,
        labelFontColor: '#99A6AF',
        margin: -2
      },
      axisX:{
        valueFormatString: ['5m', '15m', '1h'].includes(period) ? 'HH:mm' : 'MM-DD',
        interval: 4,
        title: 'D',
        titleFontSize: 2,
        gridThickness: 0,
        lineThickness: 0,
        tickLength: 0,
        labelFontSize: 10,
        labelFontColor: '#99A6AF'
      },
      data: [{
        fallingColor: '#FF5151',
        risingColor: '#38B781',
        axisYType: 'secondary',
        type: 'candlestick',
        dataPoints : stock,
      }]
    }]
  }

  return (
    period === EChart.line ? (
      line.length > 0 && <CanvasJSChart options={lineOption} />
    ) : (
      stock.length > 0 && <CanvasJSStockChart options={stockOption} />
    )
  );
}

export default View;
