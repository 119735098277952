import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer, fullDrawer } from 'services/reducer/drawer';
import version from '../../../../package.json';
import CardList from '../CardList';
import Chat from '../Chat';
import LevelUp from '../LevelUp';
import Security from '../Security';
import Address from './Address';
import Edit from './Edit';
import Levels from './Levels';
import Logout from './Logout';

const UserMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useUser();
  const { levels } = useGlobal();

  return (
    <div className='information'>
      <div className='avatar' style={{ background: `#${levels[profile.level].color}` }}>
        <span><img src='/images/avatar.png' alt='' /></span>
        <div>
          <i>{profile.fullName || t('guest')}</i>
          <i>
            ({t('level')} {levels[profile.level].title})
            <b onClick={() => dispatch(fullDrawer(<LevelUp />))}>{t('levelUp')}</b>
          </i>
        </div>
      </div>
      <div className='basic'>
        <span onClick={() => dispatch(fullDrawer(<Edit />))}>{t('profile')}</span>
        <span onClick={() => dispatch(fullDrawer(<CardList />))}>{t('bankInfo')}</span>
        <span onClick={() => dispatch(fullDrawer(<Address />))}>{t('addressInfo')}</span>
      </div>
      <div className='basic basicTwo'>
        <span onClick={() => dispatch(fullDrawer(<Security />))}>{t('security')}</span>
      </div>
      <div className='basic basicThree'>
        <span onClick={() => dispatch(fullDrawer(<Chat />))}>{t('support')}</span>
      </div>
      <div className='other setting'>
        <span onClick={() => dispatch(fullDrawer(<Levels />))}>{t('levelInfo')}</span>
        <span onClick={() => dispatch(bottomDrawer(<Logout />))}>{t('logout')}</span>
      </div>
      <div className='rules'>
        <span>{t('rulesAndFaq')}</span>
        <span>{t('contactUs')}: 02188982991</span>
        <span onClick={() => window.location.reload()}>version: {version.version}</span>
      </div>
    </div>
  )
}

export default UserMenu;
