import Alarm from 'components/Icons/Alarm';
import Profile from 'components/Icons/Profile';
import Support from 'components/Icons/Support';
import Menu from 'components/Layouts/Menu';
import Shortcuts from 'components/Layouts/Shortcuts';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { refreshPage } from 'utils/tools';
import { isUserLogin } from 'utils/user';
import Balance from './Balance';
import Info from './Info';

const Wallet = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0);

    if(!isUserLogin()){
      navigate('/');
    }
  }, []);

  return (
    <>
      <Menu />
      <PullToRefresh onRefresh={() => refreshPage()}>
        <>
          <div className='main'>
            <header className='header headerWallet'>
              <Alarm />
              <Support />
              <div className='topOfSearch'></div>
              <Profile />
            </header>
            <section className='walletBlue'>
              <div className='dexWallet'>Wallet</div>
              <i></i>
              <i></i>
              <i></i>
              <Info />
            </section>
            <Shortcuts />
            <Balance />
          </div>
          <div className='extraBottom'></div>
        </>
      </PullToRefresh>
    </>
  );
}

export default Wallet;
