import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeExUnit } from 'services/reducer/global';

const Language = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const getLanguage = () => {
    return i18n.language || window.localStorage.i18nLng
  }

  const changeLanguage = () => {
    if (getLanguage() === 'en') {
      dispatch(changeExUnit('USD'))
      i18n.changeLanguage('fr')
    } else if(getLanguage() === 'fr'){
      dispatch(changeExUnit('USD'))
      i18n.changeLanguage('ar')
    } else if(getLanguage() === 'ar') {
      dispatch(changeExUnit('TMN'))
      i18n.changeLanguage('fa')
    } else if(getLanguage() === 'fa') {
      dispatch(changeExUnit('USD'))
      i18n.changeLanguage('en')
    }
    Cookies.set('language', i18n.language, { expires: 30 * 24 * 60 * 60, path: '/' });
    document.documentElement.lang = i18n.language;
  }

  // return ('');
  return (<img className='headLang' onClick={() => { changeLanguage() }} src={`./images/${getLanguage()}.png`} />);
}

export default Language;
