import CanvasJSReactLine from 'assets/js/canvasjs.react';
import { IChart, IChartProps } from 'interfaces/wallet';
import moment from 'jalali-moment';

const Chart = ({ data }: IChartProps) => {
  const CanvasJSChart = CanvasJSReactLine.CanvasJSChart;

  const chart: IChart[] = [];
  data.forEach((e) => {
    const date = (typeof e.createdAt === 'string') ? new Date(e.createdAt).getTime() : e.createdAt;

    chart.push({
      label: moment(date).locale('fa').format('DD-MM'),
      y: e.amount,
    })
  });

  chart.reverse();

  const options = {
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: false,
    backgroundColor: '#272C34',
    height: 120,
    axisY2:{
      gridThickness: 1,
      gridColor: '#313842',
      lineThickness: 0,
      tickLength: 0,
      labelFontSize: 10,
      labelFontColor: '#99A6AF',
      margin: -2,
    },
    axisX:{
      title: 'D',
      titleFontSize: 2,
      titleFontColor: '#171A1F',
      gridThickness: 0,
      lineThickness: 0,
      tickLength: 0,
      labelFontSize: 10,
      labelFontColor: '#99A6AF',
    },
    toolTip:{
      enabled: false,
    },
    data: [{
      type: 'area',
      lineColor: '#4598D3',
      color: '#4598D3',
      markerType: 'none',
      axisYType: 'secondary',
      dataPoints: chart,
    }]
  }

  return (
    <div className='currencyChart'>
      <CanvasJSChart options={options} />
    </div>
  )
}

export default Chart;
