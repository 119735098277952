import CoinList from 'components/Layouts/CoinList';
import OpenLogin from 'components/Layouts/OpenLogin';
import Express from 'components/Modules/Express';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bottomDrawer, fullDrawer, leftDrawer } from 'services/reducer/drawer';
import { formatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const FastBuy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencyUnit } = useGlobal();
  const { balance } = useUser();
  const [select, setSelect] = useState('');

  useEffect(() => {
    if (select) {
      setTimeout(() => {
          dispatch(fullDrawer(<Express value={select} />));
      }, 250);
    }
  }, [select]);
  
  return (
    <div className='charge' onClick={() => isUserLogin() ? dispatch(leftDrawer(<CoinList setSelect={setSelect} />)) : dispatch(bottomDrawer(<OpenLogin />))}>
      <span></span>
      <h5>{t('myStock')}</h5>
      <div>
        <i>{currencyUnit}</i>
        <b>{balance.account[currencyUnit] ? formatter(balance.account[currencyUnit].free, 0, 0) : 0}</b>
      </div>
      <h6>{t('expressBuy')}</h6>
    </div>
  )
}

export default FastBuy;
