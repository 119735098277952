import Tabs from 'components/Helper/Tabs';
import Support from 'components/Icons/Support';
import Menu from 'components/Layouts/Menu';
import OpenLogin from 'components/Layouts/OpenLogin';
import PriceAlert from 'components/Modules/PriceAlert';
import config from 'config';
import { ETab } from 'enums/currency';
import { ETransaction } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { IOrderList } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { bottomDrawer, fullDrawer } from 'services/reducer/drawer';
import { priceColor } from 'utils/color';
import { autoFormatter, formatter } from 'utils/numeral';
import { refreshPage } from 'utils/tools';
import { isUserLogin } from 'utils/user';
import Chart from './Chart';
import DeepData from './DeepData';
import Favorite from './Favorite';
import OrderBook from './OrderBook';

const Currency = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(ETab.request);
  const [randomCandle, setRandomCandle] = useState(false);
  const { price, change, volume, high, low } = useTicker();
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState<IOrderList>({ buy: [], sell: [] });
  const dispatch = useDispatch();
  const { pairs } = useGlobal();
  const symbol = searchParams.get('symbol')!;

  const tabTitle = {
    [ETab.request]: t('requests'), 
    [ETab.info]: t('information'), 
  }

  const data = pairs[symbol];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menu />
      <PullToRefresh onRefresh={() => refreshPage()}>
        <>
          <div className='main'>
            <header className='header headerMarket'>
              <Favorite symbol={symbol} />
              {config.source === 'corypton' ? <div className='headIcon ai' onClick={() => setRandomCandle(!randomCandle)}></div> : <Support />}
              <div className='headIcon alert' onClick={() => isUserLogin() ? dispatch(fullDrawer(<PriceAlert value={symbol} />)) : dispatch(bottomDrawer(<OpenLogin />))}></div>
              <div className='info'>
                <div className='back' onClick={() => navigate(-1)}></div>
                <span>
                  <h2>{`${data.base}/${data.quote}`}</h2>
                  <p className={`color${priceColor(change(symbol))}`}>{formatter(change(symbol), 2, 2)}%</p>
                </span>
              </div>
            </header>
            <section className='detail'>
              <div>
                <p>Current price</p>
                <span>{autoFormatter(price(symbol))}</span>
                <i>{data.quote}</i>
              </div>
              <div>
                <span>24h High<i>{autoFormatter(high(symbol))}</i></span>
                <span>24h Vol ({data.quote})<i>{autoFormatter(volume(symbol))}</i></span>
                <span>24h Low<i>{autoFormatter(low(symbol))}</i></span>
                <span>Amount ({data.base})<i>{autoFormatter(volume(symbol) / price(symbol))}</i></span>
              </div>
            </section>
            <Chart symbol={symbol} randomCandle={randomCandle} setRandomCandle={setRandomCandle} />
            <DeepData symbol={symbol} data={data} orderList={orderList} />
            <div className='infoCurrency'>
              <div className='infoTab'>
                <Tabs titles={tabTitle} selected={tab} setSelected={setTab} />
              </div>
              {tab === ETab.request ? (
                <OrderBook symbol={symbol} setOrderList={setOrderList} orderList={orderList} />
              ) : (
                <div className='infoData'>
                  <div className='notFound'>{t('notFound')}</div>
                  <br/><br/>
                </div>
              )}
            </div>
          </div>
          <div className='extraBottom'></div>
        </>
      </PullToRefresh>
      <div className='requestButton requestButtonFixed'>
        <Link to={`/trade/?symbol=${symbol}`} state={{type: ETransaction.sell}}>{t('sell')}</Link>
        <Link to={`/trade/?symbol=${symbol}`} state={{type: ETransaction.buy}}>{t('buy')}</Link>
      </div>
    </>
  );
}

export default Currency;
