import useGlobal from 'hooks/useGlobal';
import { useTranslation } from 'react-i18next';
import { autoFormatter } from 'utils/numeral';

const BuySell = ({ value }: any) => {
  const { t } = useTranslation();
  const { pairs } = useGlobal();
  
  const data = pairs[value.pair];

  return (
    <div>
      <b><i>{t('price')}</i> {autoFormatter(value.price)} {data.quote}</b>
      <b><i>{t('amount')}</i> {autoFormatter(value.amount)} {data.base}</b>
      <b><i>{t('sum')}</i> {autoFormatter(value.total)} {data.quote}</b>
    </div>
  );
}

export default BuySell;
