import { configureStore } from '@reduxjs/toolkit';
import alert from '../reducer/alert';
import drawer from '../reducer/drawer';
import favorite from '../reducer/favorite';
import global from '../reducer/global';
import loading from '../reducer/loading';
import popup from '../reducer/popup';
import ticker from '../reducer/ticker';
import user from '../reducer/user';

export default configureStore({
  reducer: {
    loading,
    global,
    alert: alert.a,
    favorite,
    popup,
    user,
    ticker,
    drawer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})
